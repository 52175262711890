import React from 'react';
import bannerImage from '../assets/banners/home-bg.png';

const Hero = ({ openBookingForm }) => {
  return (
    <section className="relative min-h-[500px] sm:min-h-[65vh] flex items-center justify-center overflow-hidden pt-20 pb-16 sm:py-0">
      <div className="absolute inset-0">
        <div className="w-full h-full" style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(0.15)',
        }}></div>
      </div>
      
      <div className="relative z-10 container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center space-y-3 sm:space-y-6">
          <div className="space-y-2 sm:space-y-3">
            <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold text-white leading-tight">
              Expert Appliance Repairs
              <span className="text-primary-400 block mt-1 sm:mt-2">When You Need Them Most</span>
            </h1>
            <p className="text-base sm:text-xl md:text-2xl text-gray-200 font-medium">
              Professional Service from £80 • 90-Day Guarantee
            </p>
          </div>

          {/* Trust Indicators */}
          <div className="flex flex-wrap justify-center gap-2 sm:gap-6 px-2 sm:px-0">
            <div className="bg-white/10 backdrop-blur-sm px-3 sm:px-4 py-2 rounded-lg flex items-center">
              <i className="fas fa-certificate text-primary-400 mr-2"></i>
              <span className="text-white text-xs sm:text-sm">Certified Engineers</span>
            </div>
            <div className="bg-white/10 backdrop-blur-sm px-3 sm:px-4 py-2 rounded-lg flex items-center">
              <i className="fas fa-check-circle text-primary-400 mr-2"></i>
              <span className="text-white text-xs sm:text-sm">All Major Brands</span>
            </div>
            <div className="bg-white/10 backdrop-blur-sm px-3 sm:px-4 py-2 rounded-lg flex items-center">
              <i className="fas fa-tools text-primary-400 mr-2"></i>
              <span className="text-white text-xs sm:text-sm">Same-Day Service</span>
            </div>
          </div>

          {/* Stats */}
          <div className="flex justify-center gap-4 sm:gap-12">
            <div className="text-center">
              <div className="text-xl sm:text-3xl font-bold text-white">90%</div>
              <div className="text-xs sm:text-sm text-gray-300">Same-Day Rate</div>
            </div>
            <div className="text-center">
              <div className="text-xl sm:text-3xl font-bold text-white">98%</div>
              <div className="text-xs sm:text-sm text-gray-300">Satisfaction</div>
            </div>
            <div className="text-center">
              <div className="text-xl sm:text-3xl font-bold text-white">15+</div>
              <div className="text-xs sm:text-sm text-gray-300">Years Experience</div>
            </div>
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-2 sm:gap-4 pt-3 sm:pt-4">
            <button 
              onClick={openBookingForm}
              className="bg-primary-500 text-white px-6 sm:px-8 py-3 rounded-lg text-base sm:text-lg font-medium hover:bg-primary-600 active:bg-primary-700 transition-colors flex items-center justify-center shadow-lg group touch-manipulation"
            >
              Book Your Repair
              <i className="fas fa-arrow-right ml-2 group-hover:translate-x-1 transition-transform"></i>
            </button>
            <a 
              href="tel:+443308183946"
              className="bg-white/10 backdrop-blur-sm text-white px-6 sm:px-8 py-3 rounded-lg text-base sm:text-lg font-medium hover:bg-white/20 active:bg-white/30 transition-colors flex items-center justify-center group touch-manipulation"
            >
              <i className="fas fa-phone-alt mr-2 group-hover:rotate-12 transition-transform"></i>
              0330 818 3946
            </a>
          </div>

          {/* Google Reviews */}
          <div className="inline-flex items-center bg-white/10 backdrop-blur-sm px-3 sm:px-4 py-2 rounded-lg mt-2 sm:mt-0">
            <div className="flex text-yellow-400 mr-2">
              {[1,2,3,4,5].map((star) => (
                <i key={star} className="fas fa-star text-xs sm:text-sm"></i>
              ))}
            </div>
            <span className="text-white text-xs sm:text-sm">4.8 on Google Reviews</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
