/** @type {import('./types').BrandDetails} */
const falcon = {
  name: 'Falcon',
  logo: 'falcon.png',
  description: `Falcon is a prestigious manufacturer of professional-grade range cookers, combining traditional craftsmanship with modern technology. Our expertise with their sophisticated cooking systems ensures we can diagnose and repair these premium appliances to maintain their exceptional cooking performance.`,
  specializations: [
    'Range cooker expertise',
    'Gas system specialists',
    'Temperature control',
    'Professional grade repairs'
  ],
  applianceTypes: [
    'Range Cookers',
    'Dual Fuel Ranges',
    'Gas Ranges',
    'Built-in Ovens',
    'Cooker Hoods'
  ],
  commonIssues: [
    'Temperature control problems',
    'Gas burner malfunctions',
    'Ignition system failures',
    'Oven door mechanism issues',
    'Control panel faults',
    'Thermostat problems'
  ],
  expertise: [
    'Range cooking specialists',
    'Gas system experts',
    'Quality parts and service',
    'Professional grade knowledge',
    'Comprehensive solutions'
  ],
  faqs: [
    {
      question: 'How often should Falcon ranges be serviced?',
      answer: 'We recommend annual maintenance for Falcon range cookers to ensure safe operation and optimal performance. Regular servicing helps prevent issues and maintains cooking precision.'
    },
    {
      question: 'Can you repair both gas and electric Falcon ranges?',
      answer: 'Yes, we have extensive experience with both gas and electric Falcon ranges, including dual fuel models. Our expertise covers all fuel types and systems.'
    },
    {
      question: 'Do you repair Falcon\'s advanced features?',
      answer: 'Yes, we service all Falcon range features, from traditional gas systems to modern electronic controls and programming functions.'
    },
    {
      question: 'Are genuine Falcon parts available?',
      answer: 'Yes, we use genuine Falcon parts to ensure optimal performance and longevity. Our experience shows these provide the best reliability for these premium appliances.'
    }
  ]
};

module.exports = falcon;
