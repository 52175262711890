import React from 'react';
import { Helmet } from 'react-helmet-async';
import CTAButton from './CTAButton';
import FAQSection from './FAQSection';
import { baseKeywords, getBrandKeywords, getApplianceKeywords } from '../data/baseKeywords';

// Import brand logos
import mieleLogo from '../assets/logos/brand-logos/miele.png';
import boschLogo from '../assets/logos/brand-logos/bosch.png';
import neffLogo from '../assets/logos/brand-logos/neff.png';
import siemensLogo from '../assets/logos/brand-logos/siemens.png';
import liebherrLogo from '../assets/logos/brand-logos/liebherr.png';
import fisherPaykelLogo from '../assets/logos/brand-logos/fisher-paykel.png';
import falconLogo from '../assets/logos/brand-logos/falcon.png';
import rangemasterLogo from '../assets/logos/brand-logos/rangemaster.png';
import elicaLogo from '../assets/logos/brand-logos/elica.png';

const logoMap = {
  'miele.png': mieleLogo,
  'bosch.png': boschLogo,
  'neff.png': neffLogo,
  'siemens.png': siemensLogo,
  'liebherr.png': liebherrLogo,
  'fisher-paykel.png': fisherPaykelLogo,
  'falcon.png': falconLogo,
  'rangemaster.png': rangemasterLogo,
  'elica.png': elicaLogo
};

const formatBrandUrl = (brandName) => {
  return brandName
    .toLowerCase()
    .replace(/\s*&\s*/g, '-') // Replace "& " or " & " with a single hyphen
    .replace(/\s+/g, '-'); // Replace remaining spaces with hyphens
};

const BrandPageTemplate = ({ brand, openBookingForm }) => {
  // Combine base keywords with brand-specific and appliance-specific keywords
  const brandKeywords = getBrandKeywords(brand.name);
  
  // Generate keywords for each appliance type this brand offers
  const applianceKeywords = brand.applianceTypes.flatMap(applianceType => 
    getApplianceKeywords(`${brand.name} ${applianceType.toLowerCase()}`)
  );
  
  const combinedKeywords = [
    ...baseKeywords,
    ...brandKeywords,
    ...applianceKeywords,
    `${brand.name} repair London`,
    `${brand.name} repair southeast`,
    `${brand.name} service center`,
    `${brand.name} certified engineers`,
    `${brand.name} parts replacement`,
    `${brand.name} error codes`,
    'premium appliance service',
    'appliance repair specialists'
  ];

  const seoData = {
    title: `${brand.name} Appliance Repair Specialists | Southeast Appliances`,
    description: `Expert ${brand.name} appliance repair services. Years of experience, quality parts, and comprehensive repair solutions for all ${brand.name} appliances.`,
    keywords: combinedKeywords,
    canonicalUrl: `https://southeast-appliances.com/brands/${formatBrandUrl(brand.name)}-appliance-repair`,
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords.join(', ')} />
        <link rel="canonical" href={seoData.canonicalUrl} />
      </Helmet>

      <div className="container mx-auto px-4 py-6 sm:py-8">
        <div className="text-center mb-12">
          <img 
            src={logoMap[brand.logo]} 
            alt={`${brand.name} logo`}
            className="mx-auto mb-6 h-24 object-contain"
          />
          <h1 className="text-3xl sm:text-4xl font-bold text-primary-600 mb-4">
            {brand.name} Appliance Repair Specialists
          </h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            {brand.description}
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-8">
            {/* Expertise */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold text-primary-600 mb-4">
                Our {brand.name} Expertise
              </h2>
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {brand.specializations.map((spec, index) => (
                  <li key={index} className="flex items-start">
                    <svg className="h-6 w-6 text-primary-500 mr-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span>{spec}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Appliance Types */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold text-primary-600 mb-4">
                {brand.name} Appliances We Repair
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {brand.applianceTypes.map((type, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4 text-center">
                    <span className="font-medium">{type}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Common Issues */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-bold text-primary-600 mb-4">
                Common {brand.name} Appliance Issues
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {brand.commonIssues.map((issue, index) => (
                  <div key={index} className="flex items-start">
                    <svg className="h-6 w-6 text-red-500 mr-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span>{issue}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* FAQs */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <FAQSection faqs={brand.faqs} />
            </div>
          </div>

          <div className="lg:col-span-1 space-y-8">
            {/* Why Choose Us */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-bold text-primary-600 mb-4">
                Why Choose Us for {brand.name} Repairs
              </h2>
              <ul className="space-y-4">
                {brand.expertise.map((point, index) => (
                  <li key={index} className="flex items-start">
                    <svg className="h-6 w-6 text-green-500 mr-2 mt-0.5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* CTA Card */}
            <div className="bg-primary-600 rounded-lg shadow-md p-6 text-white text-center">
              <h3 className="text-xl font-bold mb-4">
                Need {brand.name} Appliance Repair?
              </h3>
              <p className="mb-6">
                Trust our experienced technicians to fix your {brand.name} appliance quickly and effectively.
              </p>
              <CTAButton
                text="Schedule Repair"
                onClick={openBookingForm}
                className="bg-white text-primary-600 hover:bg-gray-100 font-bold py-3 px-6 rounded-full transition duration-300 shadow-md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPageTemplate;
