import React from 'react';
import { Helmet } from 'react-helmet-async';

const useSEO = ({ 
  title, 
  description, 
  keywords, 
  canonicalUrl,
  breadcrumbs = [],
  type = 'website',
  localBusiness = null,
  imageUrl = 'https://southeast-appliances.com/logo512.png'
}) => {
  const keywordsString = Array.isArray(keywords) ? keywords.join(', ') : keywords;
  const baseUrl = 'https://southeast-appliances.com';

  // Generate breadcrumb structured data
  const breadcrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': breadcrumbs.map((item, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'item': {
        '@id': `${baseUrl}${item.path}`,
        'name': item.label
      }
    }))
  };

  // Generate local business structured data
  const businessData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    '@id': baseUrl,
    'name': 'Southeast Appliances',
    'image': imageUrl,
    'url': baseUrl,
    'telephone': '0330 818 3946',
    'priceRange': '££',
    'address': {
      '@type': 'PostalAddress',
      'addressRegion': 'London',
      'addressCountry': 'GB'
    },
    'geo': {
      '@type': 'GeoCoordinates',
      'latitude': 51.5074,
      'longitude': -0.1276
    },
    'areaServed': [
      'London',
      'Essex',
      'Kent',
      'Surrey',
      'East Sussex',
      'West Sussex',
      'Hertfordshire'
    ],
    'openingHoursSpecification': {
      '@type': 'OpeningHoursSpecification',
      'dayOfWeek': [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      'opens': '00:00',
      'closes': '23:59'
    }
  };

  // Add service area data if provided
  if (localBusiness) {
    businessData.serviceArea = {
      '@type': 'GeoCircle',
      'geoMidpoint': {
        '@type': 'GeoCoordinates',
        'latitude': localBusiness.lat,
        'longitude': localBusiness.lng
      },
      'geoRadius': '10000'
    };
    businessData.name = `Southeast Appliances - ${localBusiness.area}`;
  }

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywordsString} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="Southeast Appliances" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbList)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(businessData)}
      </script>
    </Helmet>
  );
};

export default useSEO;
