/** @type {import('./types').BrandDetails} */
const bosch = {
  name: 'Bosch',
  logo: 'bosch.png',
  description: `Bosch is a leading manufacturer renowned for innovative and reliable domestic appliances. Our extensive experience with Bosch appliances allows us to diagnose and repair even the most complex issues. We understand their advanced technology and ensure your appliance performs at its best.`,
  specializations: [
    'Advanced diagnostic systems',
    'Smart appliance expertise',
    'Energy system optimization',
    'Performance restoration'
  ],
  applianceTypes: [
    'Washing Machines',
    'Dryers',
    'Dishwashers',
    'Refrigerators',
    'Freezers'
  ],
  commonIssues: [
    'Error code diagnostics',
    'Water leakage problems',
    'Sensor calibration issues',
    'Motor and pump failures',
    'Door seal replacements',
    'Control panel malfunctions'
  ],
  expertise: [
    'Deep technical knowledge',
    'Smart feature specialists',
    'Quality parts and service',
    'Proven expertise',
    'Fast, reliable repairs'
  ],
  faqs: [
    {
      question: 'What makes Bosch appliances energy efficient?',
      answer: 'Bosch appliances feature advanced technologies like EcoSilence Drive motors, ActiveWater technology, and smart sensors that optimize resource usage while maintaining performance.'
    },
    {
      question: 'How long is the warranty on Bosch appliances?',
      answer: 'Bosch typically offers a 2-year manufacturer warranty, but many models come with extended warranty options. Our expert repairs help maintain your appliance\'s performance throughout its lifetime.'
    },
    {
      question: 'Can you repair smart features on Bosch appliances?',
      answer: 'Yes, we have extensive experience diagnosing and repairing Home Connect and other smart features on Bosch appliances, ensuring all connectivity and smart functions work properly.'
    },
    {
      question: 'Do you service older Bosch models?',
      answer: 'Yes, we service all Bosch models, including older ones. Our experience covers the full range of Bosch appliances, and we maintain access to parts for models 10+ years old.'
    }
  ]
};

module.exports = bosch;
