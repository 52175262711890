/** @type {import('./types').BrandDetails} */
const siemens = {
  name: 'Siemens',
  logo: 'siemens.png',
  description: `Siemens leads in innovative home appliance technology, combining sophisticated features with reliability. Our deep understanding of Siemens appliances and their advanced systems ensures we can diagnose and repair any issue effectively, maintaining their premium performance.`,
  specializations: [
    'Smart system expertise',
    'Advanced diagnostics',
    'Performance optimization',
    'Technical solutions'
  ],
  applianceTypes: [
    'Washing Machines',
    'Dryers',
    'Dishwashers',
    'Refrigerators',
    'Freezers'
  ],
  commonIssues: [
    'Wi-Fi connectivity problems',
    'Sensor calibration issues',
    'Water system maintenance',
    'Motor and pump repairs',
    'Control board diagnostics',
    'Display panel issues'
  ],
  expertise: [
    'Smart technology specialists',
    'Technical problem-solving',
    'Quality-focused repairs',
    'Proven experience',
    'Efficient service'
  ],
  faqs: [
    {
      question: 'Can you repair Siemens Home Connect features?',
      answer: 'Yes, we have extensive experience working with Siemens Home Connect technology, ensuring your appliances maintain their smart functionality and connectivity features.'
    },
    {
      question: 'What about Siemens\' iSensoric technology?',
      answer: 'We have extensive experience servicing Siemens\' iSensoric technology, including calibration and repair of the various automatic sensors that optimize appliance performance.'
    },
    {
      question: 'Do you provide emergency repairs for Siemens appliances?',
      answer: 'Yes, we offer emergency repair services for Siemens appliances, with rapid response times to minimize disruption to your household.'
    },
    {
      question: 'How long do Siemens appliances typically last?',
      answer: 'With proper maintenance, Siemens appliances typically last 10-15 years. Regular servicing by experienced technicians can help extend this lifespan and maintain optimal performance.'
    }
  ]
};

module.exports = siemens;
