import React from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  {
    name: "Sarah Thompson",
    location: "London",
    appliance: "Washing Machine",
    rating: 5,
    text: "Fantastic service! The engineer arrived within 2 hours and fixed my washing machine on the spot. Very professional and reasonably priced.",
    date: "Last week"
  },
  {
    name: "James Wilson",
    location: "Kent",
    appliance: "Fridge",
    rating: 5,
    text: "Called in the morning about my broken fridge, engineer came same day and identified the problem immediately. Great service, highly recommend!",
    date: "2 weeks ago"
  },
  {
    name: "Emma Davis",
    location: "Surrey",
    appliance: "Oven",
    rating: 5,
    text: "Excellent service from start to finish. The booking process was easy, the engineer was knowledgeable and fixed my oven quickly. Fair pricing too!",
    date: "Last month"
  }
];

const Testimonials = ({ openBookingForm }) => {
  return (
    <section className="py-16 sm:py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">What Our Customers Say</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Don't just take our word for it. Here's what customers across Southeast England say about our appliance repair service.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="bg-white rounded-xl shadow-lg p-6 relative"
            >
              {/* Quote Icon */}
              <div className="absolute -top-4 left-6 bg-primary-500 text-white w-8 h-8 rounded-full flex items-center justify-center">
                <i className="fas fa-quote-right"></i>
              </div>

              {/* Rating */}
              <div className="flex text-yellow-400 mb-3">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <i key={i} className="fas fa-star"></i>
                ))}
              </div>

              {/* Testimonial Text */}
              <p className="text-gray-600 mb-4 italic">"{testimonial.text}"</p>

              {/* Customer Info */}
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-semibold text-gray-800">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.location}</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-primary-500">{testimonial.appliance}</p>
                  <p className="text-xs text-gray-400">{testimonial.date}</p>
                </div>
              </div>

              {/* Verified Badge */}
              <div className="absolute top-4 right-4 text-green-500 flex items-center text-sm">
                <i className="fas fa-check-circle mr-1"></i>
                <span className="text-xs">Verified</span>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Google Reviews Summary */}
        <div className="mt-12 text-center">
          <div className="inline-flex items-center bg-white px-6 py-3 rounded-full shadow-md">
            <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5 mr-2" />
            <div className="flex text-yellow-400 mr-2">
              {[1,2,3,4,5].map((star) => (
                <i key={star} className="fas fa-star text-sm"></i>
              ))}
            </div>
            <span className="font-medium">4.8/5 from 500+ reviews</span>
          </div>
        </div>

        {/* CTA */}
        <div className="mt-12 text-center">
          <button
            onClick={openBookingForm}
            className="bg-primary-500 text-white px-8 py-3 rounded-lg font-medium hover:bg-primary-600 transition-colors inline-flex items-center group"
          >
            Book Your Repair
            <i className="fas fa-arrow-right ml-2 group-hover:translate-x-1 transition-transform"></i>
          </button>
          <p className="mt-4 text-sm text-gray-500">
            90-Day repair guarantee • Fixed price repairs from £80
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
