import React from 'react';
import { Marker, Source, Layer } from 'react-map-gl';

const MapMarkers = ({ locations, selectedRegion, onRegionSelect }) => {
  // Generate GeoJSON for region boundaries with smooth curves
  const generateRegionBoundaries = (region) => {
    const towns = region.regions.flatMap(r => r.towns);
    
    // Get the center point of all towns
    const centerLat = towns.reduce((sum, t) => sum + t.lat, 0) / towns.length;
    const centerLng = towns.reduce((sum, t) => sum + t.lng, 0) / towns.length;
    
    // Generate additional points between towns for smoother curves
    const points = towns.flatMap((town, i) => {
      const nextTown = towns[(i + 1) % towns.length];
      const midLat = (town.lat + nextTown.lat) / 2;
      const midLng = (town.lng + nextTown.lng) / 2;
      
      // Add slight random offset to mid points for more natural shape
      const offset = 0.02;
      const randLat = midLat + (Math.random() - 0.5) * offset;
      const randLng = midLng + (Math.random() - 0.5) * offset;
      
      return [
        [town.lng, town.lat],
        [randLng, randLat]
      ];
    }).flat();
    
    // Sort points in clockwise order around center
    points.sort((a, b) => {
      const angleA = Math.atan2(a[1] - centerLat, a[0] - centerLng);
      const angleB = Math.atan2(b[1] - centerLat, b[0] - centerLng);
      return angleA - angleB;
    });
    
    // Close the polygon
    points.push(points[0]);
    
    return {
      type: 'Feature',
      properties: { name: region.name },
      geometry: {
        type: 'Polygon',
        coordinates: [points]
      }
    };
  };

  const regionBoundaries = {
    type: 'FeatureCollection',
    features: locations.map(generateRegionBoundaries)
  };

  return (
    <>
      {/* Region Boundaries */}
      <Source id="region-boundaries" type="geojson" data={regionBoundaries}>
        <Layer
          id="region-fills"
          type="fill"
          paint={{
            'fill-color': selectedRegion ? [
              'match',
              ['get', 'name'],
              selectedRegion.name,
              'rgba(255, 0, 89, 0.15)',
              'rgba(0, 123, 255, 0.05)'
            ] : 'rgba(0, 123, 255, 0.05)',
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.2,
              1
            ]
          }}
        />
        <Layer
          id="region-borders"
          type="line"
          paint={{
            'line-color': selectedRegion ? [
              'match',
              ['get', 'name'],
              selectedRegion.name,
              '#ff0059',
              'rgba(0, 123, 255, 0.3)'
            ] : 'rgba(0, 123, 255, 0.3)',
            'line-width': selectedRegion ? [
              'match',
              ['get', 'name'],
              selectedRegion.name,
              3,
              1.5
            ] : 1.5,
            'line-opacity': 1,
            'line-blur': selectedRegion ? [
              'match',
              ['get', 'name'],
              selectedRegion.name,
              1,
              0
            ] : 0
          }}
        />
      </Source>

      {/* Major Town Markers */}
      {locations.map(location =>
        location.regions.map(region =>
          region.towns
            .filter(town => town.name.length > 0) // Filter out empty names
            .map((town, townIndex) => (
              <Marker
                key={`${location.name}-${region.name}-${town.name}-${townIndex}`}
                latitude={town.lat}
                longitude={town.lng}
                anchor="bottom"
              >
                <div className="relative group">
                  <div className="w-8 h-8 bg-white rounded-full shadow-lg cursor-pointer flex items-center justify-center transform hover:scale-110 transition-transform duration-200">
                    <i className="fas fa-wrench text-primary-500"></i>
                    <div className="absolute inset-0 bg-primary-500 rounded-full animate-ping opacity-20"></div>
                  </div>
                  
                  {/* Tooltip */}
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-3 px-3 py-2 bg-white rounded-lg shadow-xl text-sm whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    <div className="font-medium">{town.name}</div>
                    <div className="text-xs text-gray-500">{town.postcode}</div>
                  </div>
                </div>
              </Marker>
            ))
        )
      )}

      {/* Region Labels */}
      {locations.map(location => {
        const towns = location.regions.flatMap(r => r.towns);
        const centerLat = towns.reduce((sum, t) => sum + t.lat, 0) / towns.length;
        const centerLng = towns.reduce((sum, t) => sum + t.lng, 0) / towns.length;

        return (
          <Marker
            key={location.name}
            latitude={centerLat}
            longitude={centerLng}
            anchor="center"
          >
            <div className="bg-white px-3 py-1 rounded-full shadow-md text-sm font-medium text-gray-700 whitespace-nowrap">
              {location.name}
            </div>
          </Marker>
        );
      })}
    </>
  );
};

export default MapMarkers;
