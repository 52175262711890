/**
 * Common SEO keywords used across multiple pages
 * These keywords can be imported and extended with page-specific keywords
 */
export const baseKeywords = [
  "appliance repair London",
  "appliance repair southeast",
  "emergency appliance repair",
  "same day repair service", 
  "appliance engineer Kent",
  "appliance repair Essex",
  "appliance repair Surrey",
  "appliance repair Sussex",
  "fixed price appliance repair",
  "domestic appliance services",
  "24/7 appliance repair",
  "appliance installation southeast"
];

/**
 * Get appliance-specific keywords
 * @param {string} applianceType - Type of appliance (e.g., "washing machine", "fridge")
 * @returns {Array} - Array of appliance-specific keywords
 */
export const getApplianceKeywords = (applianceType) => [
  `${applianceType} repair`,
  `${applianceType} installation`,
  `${applianceType} maintenance`,
  `${applianceType} service`,
  `emergency ${applianceType} repair`
];

/**
 * Get brand-specific keywords
 * @param {string} brandName - Name of the brand (e.g., "Bosch", "Miele")
 * @returns {Array} - Array of brand-specific keywords
 */
export const getBrandKeywords = (brandName) => [
  `${brandName} appliance repair`,
  `${brandName} service`,
  `${brandName} engineer`,
  `${brandName} specialist`,
  `${brandName} parts replacement`
];

/**
 * Get location-specific keywords
 * @param {string} location - Location name (e.g., "London", "Essex")
 * @returns {Array} - Array of location-specific keywords
 */
export const getLocationKeywords = (location) => [
  `appliance repair ${location}`,
  `appliance engineer ${location}`,
  `same day repair service ${location}`,
  `appliance installation ${location}`
];
