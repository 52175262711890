import React from 'react';
import { SelectWithIcon, InputWithIcon } from './FormComponents';

const applianceTypes = [
  { value: 'WASHING_MACHINE', label: 'Washing Machine' },
  { value: 'DRYER', label: 'Dryer' },
  { value: 'DISHWASHER', label: 'Dishwasher' },
  { value: 'FRIDGE', label: 'Fridge' },
  { value: 'FREEZER', label: 'Freezer' },
  { value: 'OVEN', label: 'Oven' },
  { value: 'COOKER', label: 'Cooker' },
  { value: 'HOB', label: 'Hob' },
  { value: 'COOKER_HOOD', label: 'Cooker Hood' },
  { value: 'MICROWAVE', label: 'Microwave' },
];

const applianceBrands = [
  { value: 'MIELE', label: 'Miele' },
  { value: 'BOSCH', label: 'Bosch' },
  { value: 'SIEMENS', label: 'Siemens' },
  { value: 'NEFF', label: 'Neff' },
  { value: 'LIEBHERR', label: 'Liebherr' },
  { value: 'FISHER_PAYKEL', label: 'Fisher & Paykel' },
  { value: 'FALCON', label: 'Falcon' },
  { value: 'RANGEMASTER', label: 'Rangemaster' },
  { value: 'ELICA', label: 'Elica' },
  { value: 'OTHER', label: 'Other' },
];

const RepairForm = ({ formData, handleInputChange, errors }) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SelectWithIcon
          icon="fa-cog"
          name="applianceType"
          value={formData.applianceType}
          onChange={handleInputChange}
          options={applianceTypes}
          required
          label="Appliance Type"
          error={errors.applianceType}
        />
        <SelectWithIcon
          icon="fa-trademark"
          name="applianceBrand"
          value={formData.applianceBrand}
          onChange={handleInputChange}
          options={applianceBrands}
          required
          label="Brand"
          error={errors.applianceBrand}
        />
      </div>

      <div className="bg-gray-50 rounded-xl p-6">
        <h4 className="text-lg font-semibold text-gray-800 mb-4">Fault Description</h4>
        <InputWithIcon
          icon="fa-exclamation-circle"
          type="textarea"
          name="faultDescription"
          value={formData.faultDescription}
          onChange={handleInputChange}
          placeholder="Please describe the issue you're experiencing with your appliance..."
          required
          label="What's wrong with your appliance?"
          error={errors.faultDescription}
        />
        <div className="mt-4 text-sm text-gray-600">
          <p className="font-medium mb-2">Tips for a good fault description:</p>
          <ul className="list-disc list-inside space-y-1 text-gray-500">
            <li>When did the problem start?</li>
            <li>What happens when you try to use the appliance?</li>
            <li>Are there any unusual noises, smells, or error codes?</li>
            <li>Have you tried any fixes already?</li>
          </ul>
        </div>
      </div>

      <div className="bg-primary-50 rounded-xl p-6">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0 mt-1">
            <i className="fas fa-info-circle text-primary-500 text-xl"></i>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-800 mb-2">What happens next?</h4>
            <ul className="space-y-2 text-gray-600">
              <li className="flex items-center">
                <i className="fas fa-check text-primary-500 mr-2"></i>
                We'll review your repair request
              </li>
              <li className="flex items-center">
                <i className="fas fa-check text-primary-500 mr-2"></i>
                Confirm appointment time
              </li>
              <li className="flex items-center">
                <i className="fas fa-check text-primary-500 mr-2"></i>
                Send an engineer with the right parts
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairForm;
