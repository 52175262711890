import React from 'react';
import { SelectWithIcon, InputWithIcon } from './FormComponents';

const applianceTypes = [
  { value: 'WASHING_MACHINE', label: 'Washing Machine' },
  { value: 'DRYER', label: 'Dryer' },
  { value: 'DISHWASHER', label: 'Dishwasher' },
  { value: 'FRIDGE', label: 'Fridge' },
  { value: 'FREEZER', label: 'Freezer' },
  { value: 'OVEN', label: 'Oven' },
  { value: 'COOKER', label: 'Cooker' },
  { value: 'HOB', label: 'Hob' },
  { value: 'COOKER_HOOD', label: 'Cooker Hood' },
  { value: 'MICROWAVE', label: 'Microwave' },
];

const applianceBrands = [
  { value: 'MIELE', label: 'Miele' },
  { value: 'BOSCH', label: 'Bosch' },
  { value: 'SIEMENS', label: 'Siemens' },
  { value: 'NEFF', label: 'Neff' },
  { value: 'LIEBHERR', label: 'Liebherr' },
  { value: 'FISHER_PAYKEL', label: 'Fisher & Paykel' },
  { value: 'FALCON', label: 'Falcon' },
  { value: 'RANGEMASTER', label: 'Rangemaster' },
  { value: 'ELICA', label: 'Elica' },
  { value: 'OTHER', label: 'Other' },
];

const InstallationForm = ({ formData, handleInputChange, errors }) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SelectWithIcon
          icon="fa-cog"
          name="applianceType"
          value={formData.applianceType}
          onChange={handleInputChange}
          options={applianceTypes}
          required
          label="Appliance Type"
          error={errors.applianceType}
        />
        <SelectWithIcon
          icon="fa-trademark"
          name="applianceBrand"
          value={formData.applianceBrand}
          onChange={handleInputChange}
          options={applianceBrands}
          required
          label="Brand"
          error={errors.applianceBrand}
        />
      </div>

      <div className="bg-gray-50 rounded-xl p-6">
        <h4 className="text-lg font-semibold text-gray-800 mb-4">Installation Details</h4>
        <div className="space-y-4">
          <InputWithIcon
            icon="fa-ruler-combined"
            type="text"
            name="installationSpace"
            value={formData.installationSpace}
            onChange={handleInputChange}
            placeholder="e.g., Built-in, Under counter, Freestanding..."
            required
            label="Installation Space Type"
            error={errors.installationSpace}
          />
          <InputWithIcon
            icon="fa-clipboard"
            type="textarea"
            name="installationNotes"
            value={formData.installationNotes}
            onChange={handleInputChange}
            placeholder="Any specific requirements or concerns about the installation..."
            label="Additional Notes (Optional)"
            error={errors.installationNotes}
          />
        </div>
        <div className="mt-4 text-sm text-gray-600">
          <p className="font-medium mb-2">Important installation information:</p>
          <ul className="list-disc list-inside space-y-1 text-gray-500">
            <li>Do you have the necessary connections? (Water, gas, electric)</li>
            <li>Are there any access restrictions?</li>
            <li>Is the space prepared for the new appliance?</li>
            <li>Do you need the old appliance removed?</li>
          </ul>
        </div>
      </div>

      <div className="bg-primary-50 rounded-xl p-6">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0 mt-1">
            <i className="fas fa-info-circle text-primary-500 text-xl"></i>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-800 mb-2">Our Installation Service Includes:</h4>
            <ul className="space-y-2 text-gray-600">
              <li className="flex items-center">
                <i className="fas fa-check text-primary-500 mr-2"></i>
                Professional installation by certified engineers
              </li>
              <li className="flex items-center">
                <i className="fas fa-check text-primary-500 mr-2"></i>
                Testing and safety checks
              </li>
              <li className="flex items-center">
                <i className="fas fa-check text-primary-500 mr-2"></i>
                Demonstration of appliance features
              </li>
              <li className="flex items-center">
                <i className="fas fa-check text-primary-500 mr-2"></i>
                90-day installation warranty
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-yellow-50 rounded-xl p-6">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0 mt-1">
            <i className="fas fa-exclamation-triangle text-yellow-500 text-xl"></i>
          </div>
          <div>
            <h4 className="text-lg font-semibold text-gray-800 mb-2">Please Note</h4>
            <p className="text-gray-600">
              Our engineer will assess the installation site before proceeding. If any additional 
              parts or work is required, we'll discuss this with you before starting the installation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationForm;
