const miele = require('./miele');
const bosch = require('./bosch');
const neff = require('./neff');
const siemens = require('./siemens');
const liebherr = require('./liebherr');
const fisherPaykel = require('./fisherPaykel');
const falcon = require('./falcon');
const rangemaster = require('./rangemaster');
const elica = require('./elica');

/** @type {Record<string, import('./types').BrandDetails>} */
const brandDetails = {
  'Miele': miele,
  'Bosch': bosch,
  'Neff': neff,
  'Siemens': siemens,
  'Liebherr': liebherr,
  'Fisher & Paykel': fisherPaykel,
  'Falcon': falcon,
  'Rangemaster': rangemaster,
  'Elica': elica
};

module.exports = brandDetails;
