import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logos/SEA-Logo.png';
import QuoteBar from './QuoteBar';
import Hero from './Hero';
import locations from '../data/locations';

const Header = ({ openBookingForm }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServiceAreasOpen, setIsServiceAreasOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    if (!isHomePage) {
      setIsSticky(true);
      return;
    }

    const handleScroll = () => {
      const hero = document.querySelector('.hero');
      if (hero) {
        const heroHeight = hero.offsetHeight;
        setIsSticky(window.scrollY > heroHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isHomePage, location]); // Re-run effect when route or location changes

  const handleMouseEnter = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setIsServiceAreasOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsServiceAreasOpen(false);
    }, 300);
  };

  const closeMenus = () => {
    setIsMobileMenuOpen(false);
    setIsServiceAreasOpen(false);
  };

  const londonRegions = locations.find(location => location.name === 'London')?.regions || [];

  return (
    <>
      <header className={`${!isHomePage ? 'mb-32' : ''}`}>
        <div className={`bg-white shadow-md transition-all duration-300 w-full ${!isHomePage || isSticky ? 'fixed top-0 left-0 right-0 z-50' : ''}`}>
          <div className="container mx-auto px-4">
            <div className="flex justify-between items-center h-20">
              <div className="flex items-center">
                <Link to="/" className="focus:outline-none flex items-center pl-1 py-2" onClick={closeMenus}>
                  <div className="w-40 sm:w-52">
                    <img 
                      src={logo} 
                      alt="Southeast Appliances Logo" 
                      className="w-full h-auto"
                      style={{ 
                        display: 'block',
                        objectFit: 'contain',
                        objectPosition: 'left center'
                      }}
                    />
                  </div>
                </Link>
              </div>
              <nav className="hidden md:flex space-x-6">
                <Link to="/" className="text-gray-600 hover:text-primary-500 focus:outline-none" onClick={closeMenus}>Home</Link>
                <Link to="/brands" className="text-gray-600 hover:text-primary-500 focus:outline-none" onClick={closeMenus}>Premium Brands</Link>
                <div 
                  className="relative"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <button 
                    className="text-gray-600 hover:text-primary-500 focus:outline-none flex items-center"
                  >
                    Areas We Cover
                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  <div 
                    ref={dropdownRef}
                    className={`absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 transition-all duration-300 z-50 ${
                      isServiceAreasOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
                    }`}
                  >
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <Link
                        to="/service-areas"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 font-semibold"
                        role="menuitem"
                        onClick={closeMenus}
                      >
                        <i className="fas fa-map-marker-alt text-primary-500 mr-2"></i>
                        Full Coverage Map
                      </Link>
                      <div className="border-t border-gray-200 my-1"></div>
                      <div className="relative group">
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          London
                          <svg className="w-4 h-4 ml-1 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                          </svg>
                        </button>
                        <div className="absolute left-full top-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50">
                          {londonRegions.map((region) => (
                            <Link
                              key={region.name}
                              to={`/service-areas/london/${region.name.toLowerCase().replace(/ /g, '-')}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                              onClick={closeMenus}
                            >
                              {region.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                      {locations.filter(location => location.name !== 'London').map((location) => (
                        <Link
                          key={location.name}
                          to={`/service-areas/${location.name.toLowerCase().replace(/ /g, '-')}`}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          onClick={closeMenus}
                        >
                          {location.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <Link to="/services" className="text-gray-600 hover:text-primary-500 focus:outline-none" onClick={closeMenus}>Our Services</Link>
              </nav>
              <div className="hidden md:flex items-center space-x-4">
                <a href="tel:+443308183946" className="flex items-center text-xl font-bold text-primary-500 hover:text-primary-600 transition duration-300">
                  <span>0330 818 3946</span>
                  <i className="fas fa-phone-alt ml-2 text-lg"></i>
                </a>
                <button onClick={openBookingForm} className="bg-primary-500 text-white font-bold py-2 px-4 rounded-md hover:bg-primary-600 transition duration-300 flex items-center shadow-md">
                  <i className="fas fa-calendar-alt mr-2"></i>
                  <span>Book a Repair</span>
                </button>
              </div>
              <div className="md:hidden flex items-center justify-end w-full">
                <a href="tel:+443308183946" className="flex items-center justify-center text-lg font-bold text-primary-500 hover:text-primary-600 transition duration-300 mr-4">
                  <span className="text-sm">0330 818 3946</span>
                  <i className="fas fa-phone-alt ml-1 text-base"></i>
                </a>
                <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="text-gray-600 hover:text-primary-500 focus:outline-none">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {isMobileMenuOpen && (
            <div className="md:hidden bg-white z-50">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <Link to="/" className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none" onClick={closeMenus}>Home</Link>
                <Link to="/brands" className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none" onClick={closeMenus}>Premium Brands</Link>
                <button 
                  onClick={() => setIsServiceAreasOpen(!isServiceAreasOpen)}
                  className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none"
                >
                  Areas We Cover
                </button>
                {isServiceAreasOpen && (
                  <div className="pl-6">
                    <Link
                      to="/service-areas"
                      className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none font-semibold"
                      onClick={closeMenus}
                    >
                      <i className="fas fa-map-marker-alt text-primary-500 mr-2"></i>
                      Full Coverage Map
                    </Link>
                    <button
                      onClick={() => setIsServiceAreasOpen(!isServiceAreasOpen)}
                      className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none"
                    >
                      London
                    </button>
                    {isServiceAreasOpen && (
                      <div className="pl-6">
                        {londonRegions.map((region) => (
                          <Link
                            key={region.name}
                            to={`/service-areas/london/${region.name.toLowerCase().replace(/ /g, '-')}`}
                            className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none"
                            onClick={closeMenus}
                          >
                            {region.name}
                          </Link>
                        ))}
                      </div>
                    )}
                    {locations.filter(location => location.name !== 'London').map((location) => (
                      <Link
                        key={location.name}
                        to={`/service-areas/${location.name.toLowerCase().replace(/ /g, '-')}`}
                        className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none"
                        onClick={closeMenus}
                      >
                        {location.name}
                      </Link>
                    ))}
                  </div>
                )}
                <Link to="/services" className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-500 hover:bg-gray-50 focus:outline-none" onClick={closeMenus}>Our Services</Link>
                <button onClick={() => { openBookingForm(); closeMenus(); }} className="block w-full text-left px-3 py-2 rounded-md text-base font-medium bg-primary-500 text-white hover:bg-primary-600 focus:outline-none">
                  <i className="fas fa-calendar-alt mr-2"></i>
                  <span>Book a Repair</span>
                </button>
              </div>
            </div>
          )}
        </div>
        {isHomePage && (
          <div className="hero">
            <Hero openBookingForm={openBookingForm} />
          </div>
        )}
        <div className={`${!isHomePage || isSticky ? 'fixed top-20 left-0 right-0 z-40' : ''}`}>
          <QuoteBar openBookingForm={openBookingForm} />
        </div>
      </header>
    </>
  );
};

export default Header;
