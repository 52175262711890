/** @type {import('./types').BrandDetails} */
const rangemaster = {
  name: 'Rangemaster',
  logo: 'rangemaster.png',
  description: `Rangemaster is Britain's leading range cooker manufacturer, known for combining traditional design with modern cooking technology. Our deep understanding of their range cooking systems ensures we can provide expert repairs that maintain the exceptional performance of these premium appliances.`,
  specializations: [
    'Range cooker specialists',
    'Dual fuel expertise',
    'Temperature systems',
    'Professional maintenance'
  ],
  applianceTypes: [
    'Range Cookers',
    'Dual Fuel Ranges',
    'Electric Ranges',
    'Gas Ranges',
    'Built-in Ovens'
  ],
  commonIssues: [
    'Temperature inconsistencies',
    'Grill element failures',
    'Gas burner problems',
    'Door seal issues',
    'Control panel faults',
    'Ignition system failures'
  ],
  expertise: [
    'Range cooking experts',
    'Technical specialists',
    'Quality-focused service',
    'Comprehensive repairs',
    'Rapid response'
  ],
  faqs: [
    {
      question: 'How often should Rangemaster cookers be serviced?',
      answer: 'We recommend annual maintenance for Rangemaster cookers to ensure safe operation and optimal performance. Regular servicing helps prevent issues and maintains cooking quality.'
    },
    {
      question: 'Do you repair all Rangemaster models?',
      answer: 'Yes, we service all Rangemaster models, including classic and contemporary ranges, dual fuel, gas, and electric variants.'
    },
    {
      question: 'Can you repair Rangemaster\'s Falcon models?',
      answer: 'Yes, we have extensive experience with both Rangemaster and Falcon ranges, understanding the nuances of each brand\'s technology.'
    },
    {
      question: 'Do you use genuine Rangemaster parts?',
      answer: 'Yes, we use genuine Rangemaster parts to ensure optimal performance and longevity. Our experience shows these provide the best reliability for these premium appliances.'
    }
  ]
};

module.exports = rangemaster;
