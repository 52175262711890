import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import brandDetails from '../../data/brands';

const BrandsOverview = () => {
  const premiumBrands = [
    'Miele', 
    'Bosch', 
    'Neff', 
    'Siemens',
    'Liebherr',
    'Fisher & Paykel',
    'Falcon',
    'Rangemaster',
    'Elica'
  ];

  const formatBrandUrl = (brandName) => {
    return brandName
      .toLowerCase()
      .replace(/\s*&\s*/g, '-') // Replace "& " or " & " with a single hyphen
      .replace(/\s+/g, '-'); // Replace remaining spaces with hyphens
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Helmet>
        <title>Premium Appliance Repair Specialists | Southeast Appliances</title>
        <meta 
          name="description" 
          content="Expert repair services for premium appliance brands including Miele, Bosch, Neff, Siemens, Liebherr, Fisher & Paykel, Falcon, Rangemaster, and Elica. Experienced technicians and quality parts."
        />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-primary-600 mb-8 text-center">
            Premium Appliance Repair Specialists
          </h1>

        <div className="max-w-3xl mx-auto mb-12">
          <p className="text-lg text-gray-700 mb-6">
            We specialize in repairing and maintaining premium appliance brands. With years of experience working with sophisticated appliances, our expert technicians ensure the highest quality repairs and maintenance for your high-end appliances.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {premiumBrands.map((brandName) => {
            const brand = brandDetails[brandName];
            return (
              <Link 
                key={brandName}
                to={`/brands/${formatBrandUrl(brandName)}-appliance-repair`}
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <div className="p-6">
                  <div className="h-24 flex items-center justify-center mb-4">
                    <img
                      src={require(`../../assets/logos/brand-logos/${brand.logo}`)}
                      alt={`${brandName} logo`}
                      className="max-h-full max-w-full object-contain"
                    />
                  </div>
                  <h2 className="text-xl font-bold text-primary-600 mb-3 text-center">
                    {brandName} Repairs
                  </h2>
                  <p className="text-gray-600 text-sm mb-4">
                    {brand.description.substring(0, 150)}...
                  </p>
                  <div className="text-sm text-gray-500">
                    <strong>Specialties:</strong>
                    <ul className="mt-2 space-y-1">
                      {brand.specializations.slice(0, 3).map((spec, index) => (
                        <li key={index} className="flex items-center">
                          <svg className="h-4 w-4 text-primary-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          {spec}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        <div className="mt-12 bg-white rounded-lg shadow-md p-6 max-w-3xl mx-auto">
          <h2 className="text-2xl font-bold text-primary-600 mb-4">
            Why Choose Us for Premium Appliance Repairs?
          </h2>
          <ul className="space-y-4">
            <li className="flex items-start">
              <svg className="h-6 w-6 text-primary-500 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
              <div>
                <strong className="text-gray-800">Experienced Technicians:</strong>
                <p className="text-gray-600">Our technicians have extensive experience with premium appliances, ensuring expert repairs.</p>
              </div>
            </li>
            <li className="flex items-start">
              <svg className="h-6 w-6 text-primary-500 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
              </svg>
              <div>
                <strong className="text-gray-800">Genuine Parts:</strong>
                <p className="text-gray-600">We use only authentic manufacturer parts to maintain appliance quality and warranty.</p>
              </div>
            </li>
            <li className="flex items-start">
              <svg className="h-6 w-6 text-primary-500 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              <div>
                <strong className="text-gray-800">Rapid Response:</strong>
                <p className="text-gray-600">Fast service for all premium appliance repairs, minimizing disruption to your home.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BrandsOverview;
