import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import locations from '../data/locations';
import BrandsWeService from './BrandsWeService';
import useSEO from '../hooks/useSEO';

// ... rest of the file remains unchanged

const ServiceTypeTemplate = ({ serviceType, title, description, serviceDetails, brands, openBookingForm }) => {
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeRegion, setActiveRegion] = useState(null);
  const [postcode, setPostcode] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);
  const formattedServiceType = serviceType.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

  const regions = locations.map(location => ({
    name: location.name,
    towns: location.regions.reduce((acc, region) => acc + region.towns.length, 0),
    postcodes: location.regions.reduce((acc, region) => acc.concat(region.postcodes), [])
  }));

  const handleLocationClick = (location) => {
    setActiveLocation(location === activeLocation ? null : location);
    setActiveRegion(null);
  };

  const handleRegionClick = (region) => {
    setActiveRegion(region === activeRegion ? null : region);
  };

  const generateServiceLink = (location, region, town) => {
    const locationSlug = location.name.toLowerCase().replace(/ /g, '-');
    const regionSlug = region.name.toLowerCase().replace(/ /g, '-');
    const townSlug = town.name.toLowerCase().replace(/ /g, '-');
    const serviceSlug = serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-');

    if (location.name === region.name) {
      return `/service-areas/${locationSlug}/${townSlug}/${serviceSlug}-repair`;
    } else {
      return `/service-areas/${locationSlug}/${regionSlug}/${townSlug}/${serviceSlug}-repair`;
    }
  };

  const renderLocationLinks = (location) => (
    <div className="location mb-4">
      <button
        onClick={() => handleLocationClick(location.name)}
        className={`location-button w-full text-left px-4 py-2 bg-primary-500 text-white rounded-t-lg ${activeLocation === location.name ? 'bg-primary-600' : ''}`}
      >
        {location.name}
      </button>
      {activeLocation === location.name && (
        <div className="regions bg-white border border-primary-500 rounded-b-lg">
          {location.regions.map((region) => (
            <div key={region.name} className="region">
              {location.name !== region.name && (
                <button
                  onClick={() => handleRegionClick(region.name)}
                  className={`region-button w-full text-left px-4 py-2 hover:bg-gray-100 ${activeRegion === region.name ? 'bg-gray-200' : ''}`}
                >
                  {region.name}
                </button>
              )}
              {(activeRegion === region.name || location.name === region.name) && (
                <div className="towns pl-4">
                  {region.towns.map((town) => (
                    <div key={town.name} className="town">
                      <Link 
                        to={generateServiceLink(location, region, town)}
                        className="town-button w-full text-left px-4 py-2 hover:bg-gray-100 block text-primary-500 hover:text-primary-700"
                      >
                        {town.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  // Define breadcrumbs for structured data
  const breadcrumbs = [
    { label: 'Home', path: '/' },
    { label: 'Services', path: '/services' },
    { label: `${formattedServiceType} Repair`, path: `/services/${serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-repair` }
  ];

  // Setup SEO data with breadcrumbs
  const seoData = {
    title: title || `${formattedServiceType} Repair Services | Southeast Appliances`,
    description: description || `Professional ${formattedServiceType.toLowerCase()} repair services across various locations. Fast, reliable, and expert repairs for all types of ${formattedServiceType.toLowerCase()} units.`,
    keywords: [`${formattedServiceType.toLowerCase()}`, 'repair', 'maintenance', 'domestic appliances', 'Southeast Appliances'],
    canonicalUrl: `https://southeast-appliances.com/services/${serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-repair`,
    breadcrumbs: breadcrumbs
  };
  
  const SEO = useSEO(seoData);

  return (
    <div className="bg-gray-100 min-h-screen">
      {SEO}
      
      <div className="container mx-auto px-4 py-12">
        {/* Top Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 text-primary-600">{title || `${formattedServiceType} Repair Services`}</h1>
          <div className="flex items-center justify-center gap-4 mb-6">
            <span className="bg-primary-50 text-primary-600 px-4 py-2 rounded-full text-sm font-semibold">£80 Fixed Service Charge</span>
            <span className="bg-green-50 text-green-600 px-4 py-2 rounded-full text-sm font-semibold">Same Day Service Available</span>
          </div>
          <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto">
            {description}
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center max-w-xl mx-auto">
            <button
              onClick={openBookingForm}
              className="bg-primary-500 text-white px-8 py-4 rounded-lg hover:bg-primary-600 transition-all duration-200 text-center group flex-1"
            >
              <div className="flex items-center justify-center mb-1">
                <i className="fas fa-calendar-check mr-2 group-hover:scale-110 transition-transform"></i>
                <span>Book a Repair</span>
              </div>
              <span className="text-sm text-white/80">Instant Confirmation</span>
            </button>
            
            <a
              href="tel:0330 818 3946"
              className="bg-green-500 text-white px-8 py-4 rounded-lg hover:bg-green-600 transition-all duration-200 text-center group flex-1"
            >
              <div className="flex items-center justify-center mb-1">
                <i className="fas fa-phone-alt mr-2 group-hover:scale-110 transition-transform"></i>
                <span>Call Us Now</span>
              </div>
              <span className="text-sm text-white/80">24/7 Support</span>
            </a>
          </div>
        </div>

        {/* Common Problems Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-8 text-primary-600 text-center">Common {formattedServiceType} Problems We Fix</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: 'Not Heating',
                description: 'Faulty heating element or thermostat causing cold or partially heated cycles.'
              },
              {
                title: 'Strange Noises',
                description: 'Unusual sounds during operation, often caused by worn bearings or drum issues.'
              },
              {
                title: 'Not Spinning',
                description: 'Motor problems or belt issues preventing proper drum rotation.'
              },
              {
                title: 'Door Problems',
                description: 'Faulty door lock or seal causing safety lock issues or leaks.'
              },
              {
                title: 'Control Panel Issues',
                description: 'Unresponsive buttons or display problems affecting operation.'
              },
              {
                title: 'Excessive Vibration',
                description: 'Unbalanced drum or suspension problems causing shaking.'
              }
            ].map((problem, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
                <h3 className="text-xl font-bold mb-3 text-gray-800">{problem.title}</h3>
                <p className="text-gray-600 mb-4">{problem.description}</p>
                <div className="flex items-center justify-between">
                  <span className="text-primary-600 font-semibold">£80 Fixed Service Charge</span>
                  <button
                    onClick={openBookingForm}
                    className="text-primary-500 hover:text-primary-600 font-medium flex items-center"
                  >
                    Book Repair
                    <i className="fas fa-arrow-right ml-2 text-sm"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Brands Section */}
        <section className="mb-16">
          <div className="py-16">
            <BrandsWeService openBookingForm={openBookingForm} />
          </div>
        </section>

        {/* Postcode Checker */}
        <div className="max-w-2xl mx-auto mb-16 postcode-search scroll-mt-[120px] md:scroll-mt-0">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-semibold mb-4 text-center">Check if we cover your area</h3>
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="text"
                placeholder="Enter your postcode"
                value={postcode}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setPostcode(value);
                  if (value.length >= 2) {
                    const region = regions.find(r => r.postcodes.some(p => value.startsWith(p)));
                    setSelectedRegion(region);
                  }
                }}
                className="flex-grow px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
              <button
                onClick={() => {
                  const region = regions.find(r => r.postcodes.some(p => postcode.startsWith(p)));
                  setSelectedRegion(region);
                }}
                className="bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition-colors flex items-center justify-center whitespace-nowrap"
              >
                <span>Check Coverage</span>
                <i className="fas fa-search ml-2"></i>
              </button>
            </div>
            {selectedRegion && (
              <div className="mt-4 p-6 bg-green-50 text-green-700 rounded-lg border-2 border-green-200 shadow-lg animate-fadeIn">
                <p className="font-medium">✓ Great news! We cover {selectedRegion.name}</p>
                <p className="text-sm mt-1">Book now for same-day or next-day service</p>
                <button
                  onClick={openBookingForm}
                  className="mt-3 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors text-sm"
                >
                  Book a Repair
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Service Areas Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Service Areas for {formattedServiceType} Repair</h2>
          <p className="text-lg text-gray-700 mb-8">We offer our {formattedServiceType.toLowerCase()} repair services across various locations. Select your area to learn more about our services near you:</p>
          
          <div className="service-areas">
            <div className="locations grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {locations.map(location => renderLocationLinks(location))}
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <div className="mt-16 text-center bg-gradient-to-br from-primary-600 to-primary-500 text-white p-12 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-white">Ready to Book Your {formattedServiceType} Repair?</h2>
          <p className="text-xl text-white/90 mb-8">
            Expert repairs with same-day service • £80 Fixed Service Charge
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <button 
              onClick={openBookingForm}
              className="bg-white text-primary-600 px-8 py-4 rounded-lg font-semibold text-lg hover:bg-white/95 transition-colors duration-150 flex items-center justify-center min-w-[220px] shadow-lg"
            >
              <i className="fas fa-calendar-alt mr-3"></i>
              Book Online Now
            </button>
            <a 
              href="tel:0330 818 3946" 
              className="bg-primary-700/50 backdrop-blur-sm text-white px-8 py-4 rounded-lg font-semibold text-lg hover:bg-primary-700/60 transition-colors duration-150 flex items-center justify-center min-w-[220px]"
            >
              <i className="fas fa-phone-alt mr-3"></i>
              0330 818 3946
            </a>
          </div>
        </div>
      </div>

      {/* Floating Book Now Button */}
      <div className="fixed bottom-6 right-6 z-50">
        <button
          onClick={openBookingForm}
          className="bg-primary-500 text-white rounded-full p-4 shadow-lg hover:bg-primary-600 transition duration-300 transform hover:scale-105 flex items-center"
        >
          <i className="fas fa-wrench mr-2"></i>
          <span className="font-bold">Book Now</span>
        </button>
      </div>
    </div>
  );
};

export default ServiceTypeTemplate;
