/** @type {import('./types').BrandDetails} */
const elica = {
  name: 'Elica',
  logo: 'elica.png',
  description: `Elica is a leading manufacturer of premium cooker hoods and ventilation systems, known for innovative technology and sophisticated design. Our expertise with their advanced extraction systems ensures we can diagnose and repair these premium appliances to maintain optimal performance and efficiency.`,
  specializations: [
    'Ventilation expertise',
    'Extraction systems',
    'Motor diagnostics',
    'Filter maintenance'
  ],
  applianceTypes: [
    'Cooker Hoods',
    'Island Hoods',
    'Integrated Hoods',
    'Ceiling Hoods',
    'Downdraft Extractors'
  ],
  commonIssues: [
    'Motor performance problems',
    'Control panel malfunctions',
    'Lighting system failures',
    'Filter system issues',
    'Noise level increases',
    'Extraction power loss'
  ],
  expertise: [
    'Ventilation specialists',
    'Technical knowledge',
    'Quality parts and service',
    'System optimization',
    'Comprehensive solutions'
  ],
  faqs: [
    {
      question: 'How often should Elica hoods be serviced?',
      answer: 'We recommend annual maintenance for Elica cooker hoods to ensure optimal extraction performance and efficiency. Regular servicing helps maintain air quality and prevents issues.'
    },
    {
      question: 'Can you repair smart features on Elica hoods?',
      answer: 'Yes, we have experience with Elica\'s smart features and connectivity systems, ensuring all advanced functions work properly.'
    },
    {
      question: 'Do you service all Elica hood types?',
      answer: 'Yes, we repair all Elica hood types, from traditional wall-mounted to ceiling-integrated and downdraft models, maintaining both function and aesthetics.'
    },
    {
      question: 'How long do Elica hoods typically last?',
      answer: 'With proper maintenance, Elica hoods can last 10-15 years. Regular servicing by experienced technicians helps extend this lifespan and maintain optimal performance.'
    }
  ]
};

module.exports = elica;
