import React from 'react';

const CTAButton = ({ text, onClick, className = '' }) => {
  return (
    <div className="space-y-4">
      <button
        onClick={onClick}
        className="w-full bg-primary-500 text-white px-6 py-4 rounded-lg hover:bg-primary-600 transition-all duration-200 text-center group"
      >
        <div className="flex items-center justify-center mb-1">
          <i className="fas fa-calendar-check mr-2 group-hover:scale-110 transition-transform"></i>
          <span>{text}</span>
        </div>
        <span className="text-sm text-white/80">Instant Confirmation</span>
      </button>
      
      <button
        onClick={() => window.location.href = 'tel:0330 818 3946'}
        className="w-full bg-green-500 text-white px-6 py-4 rounded-lg hover:bg-green-600 transition-all duration-200 text-center group"
      >
        <div className="flex items-center justify-center mb-1">
          <i className="fas fa-phone-alt mr-2 group-hover:scale-110 transition-transform"></i>
          <span>Call Us Now</span>
        </div>
        <span className="text-sm text-white/80">24/7 Support</span>
      </button>
    </div>
  );
};

export default CTAButton;
