import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import applianceTypes from '../data/applianceTypes';
import BrandsWeService from '../components/BrandsWeService';
import ServiceAreasSection from '../components/ServiceAreasSection';
import Testimonials from '../components/Testimonials';

// Import appliance images
import washingMachineImg from '../assets/service_images/washing-machine.png';
import dryerImg from '../assets/service_images/dryer.png';
import dishwasherImg from '../assets/service_images/dishwasher.png';
import ovenImg from '../assets/service_images/oven.png';
import cookerImg from '../assets/service_images/cooker.png';
import hobImg from '../assets/service_images/hob.png';
import cookerHoodImg from '../assets/service_images/cooker_hood.png';
import fridgeImg from '../assets/service_images/fridge.png';
import freezerImg from '../assets/service_images/freezer.png';
import microwaveImg from '../assets/service_images/microwave.png';

const Home = ({ openBookingForm, mapboxApiKey }) => {
  const applianceTypeToUrl = {
    'Washing Machine': '/services/washing-machine',
    'Dryer': '/services/dryer',
    'Dishwasher': '/services/dishwasher',
    'Oven': '/services/oven',
    'Cooker': '/services/cooker',
    'Hob': '/services/hob',
    'Cooker Hood': '/services/cooker-hood',
    'Fridge': '/services/fridge',
    'Freezer': '/services/freezer',
    'Fridge-Freezer': '/services/fridge-freezer',
    'Microwave': '/services/microwave',
  };

  const ServiceCard = ({ icon, title, description, link }) => {
    const [{ x }, api] = useSpring(() => ({ x: 0 }));

    const bind = useDrag(({ down, movement: [mx] }) => {
      api.start({ x: down ? mx : 0, immediate: down });
      if (mx > 100 && !down) {
        openBookingForm();
      }
    });

    return (
      <animated.div
        {...bind()}
        style={{
          x,
          touchAction: 'pan-y',
        }}
        className="rounded-lg shadow-md p-4 sm:p-6 text-center hover:shadow-lg transition duration-300 cursor-grab active:cursor-grabbing bg-white"
      >
        <i className={`${icon} text-3xl sm:text-4xl text-primary-500 mb-2 sm:mb-4`}></i>
        <h3 className="text-lg sm:text-xl font-semibold mb-2">{title}</h3>
        <p className="mb-4 text-sm sm:text-base">{description}</p>
        <Link to={link} className="text-primary-500 hover:text-primary-600 font-medium text-sm sm:text-base">Learn More</Link>
        <div className="mt-4 text-sm text-gray-500">Slide to book &rarr;</div>
      </animated.div>
    );
  };

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>Professional Appliance Repairs London & Southeast | Same-Day Service Guaranteed</title>
        <meta 
          name="description" 
          content="Expert appliance repairs across London & Southeast. Professional engineers, 90-day guarantee, from £80. Same-day or next-day service for washing machines, fridges, ovens & more." 
        />
        <meta 
          name="keywords" 
          content="appliance repair London, appliance repair southeast, emergency appliance repair, same day repair service, appliance engineer Kent, appliance repair Essex, appliance repair Surrey, appliance repair Sussex, washing machine repair, fridge repair, freezer repair, oven repair, cooker repair, dishwasher repair, dryer repair, hob repair, cooker hood repair, microwave repair, Bosch appliance repair, Miele appliance repair, Siemens repair, Neff appliance repair, fixed price appliance repair, domestic appliance services, 24/7 appliance repair, appliance installation southeast" 
        />
        <meta property="og:title" content="Professional Appliance Repairs London & Southeast | Same-Day Service Guaranteed" />
        <meta 
          property="og:description" 
          content="Expert appliance repairs with 90-day guarantee. Same-day or next-day service guaranteed. Covering London, Essex, Kent, Surrey & Hertfordshire." 
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://southeast-appliances.com/logo512.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Appliance Repairs London & Southeast" />
        <meta 
          name="twitter:description" 
          content="Expert appliance repairs with 90-day guarantee. Same-day or next-day service guaranteed. Book online or call now." 
        />
        <meta name="twitter:image" content="https://southeast-appliances.com/logo512.png" />
        <link rel="canonical" href="https://southeast-appliances.com" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Southeast Appliances",
            "image": "https://southeast-appliances.com/logo512.png",
            "@id": "https://southeast-appliances.com",
            "url": "https://southeast-appliances.com",
            "telephone": "0330 818 3946",
            "priceRange": "££",
            "address": {
              "@type": "PostalAddress",
              "addressRegion": "London",
              "addressCountry": "GB"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 51.5074,
              "longitude": -0.1276
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            },
            "sameAs": [
              "https://www.facebook.com/southeastappliances",
              "https://twitter.com/seappliances"
            ]
          })}
        </script>
      </Helmet>

      {/* Services Overview */}
      <section className="py-12 sm:py-20 lg:py-24 bg-gray-50">
        <div className="container mx-auto px-4 lg:px-8 xl:px-12">
          <div className="text-center mb-8 sm:mb-16">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">Choose Your Service</h2>
            <p className="text-gray-600 text-sm sm:text-base lg:text-lg max-w-3xl mx-auto">
              Professional appliance repairs with same-day or next-day service guarantee. All repairs backed by our 90-day warranty.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
            <div className="bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition-all duration-300">
              <div className="bg-primary-50 w-16 sm:w-20 h-16 sm:h-20 rounded-full flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-clock text-2xl sm:text-3xl text-primary-500"></i>
              </div>
              <div className="inline-flex items-center bg-primary-50 text-primary-700 text-xs sm:text-sm font-medium py-1.5 px-3 rounded-full mb-4">
                <i className="fas fa-star text-[10px] sm:text-xs mr-1.5"></i>
                SAME DAY SERVICE
              </div>
              <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-3">Priority Repair</h3>
              <p className="text-gray-600 text-sm sm:text-base mb-6">Guaranteed same-day service</p>
              <ul className="text-left text-sm sm:text-base mb-6 space-y-3">
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>Priority scheduling</span>
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>Same-day guarantee</span>
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>90-day warranty</span>
                </li>
              </ul>
              <div className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">From £120</div>
              <button 
                onClick={openBookingForm} 
                className="w-full bg-primary-500 text-white text-sm sm:text-base font-medium py-3 px-6 rounded-lg hover:bg-primary-600 transition-colors flex items-center justify-center"
              >
                <span>Book Same-Day Service</span>
                <i className="fas fa-arrow-right ml-2 text-xs"></i>
              </button>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition-all duration-300 border border-primary-500">
              <div className="bg-primary-50 w-16 sm:w-20 h-16 sm:h-20 rounded-full flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-tools text-2xl sm:text-3xl text-primary-500"></i>
              </div>
              <div className="inline-flex items-center bg-primary-50 text-primary-700 text-xs sm:text-sm font-medium py-1.5 px-3 rounded-full mb-4">
                <i className="fas fa-heart text-[10px] sm:text-xs mr-1.5"></i>
                MOST POPULAR
              </div>
              <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-3">Standard Repair</h3>
              <p className="text-gray-600 text-sm sm:text-base mb-6">Next-day service guarantee</p>
              <ul className="text-left text-sm sm:text-base mb-6 space-y-3">
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>Next-day service</span>
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>Fixed price repairs</span>
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>90-day warranty</span>
                </li>
              </ul>
              <div className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">From £80</div>
              <button 
                onClick={openBookingForm} 
                className="w-full bg-primary-500 text-white text-sm sm:text-base font-medium py-3 px-6 rounded-lg hover:bg-primary-600 transition-colors flex items-center justify-center"
              >
                <span>Schedule Repair</span>
                <i className="fas fa-arrow-right ml-2 text-xs"></i>
              </button>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition-all duration-300">
              <div className="bg-primary-50 w-16 sm:w-20 h-16 sm:h-20 rounded-full flex items-center justify-center mx-auto mb-6">
                <i className="fas fa-box text-2xl sm:text-3xl text-primary-500"></i>
              </div>
              <div className="inline-flex items-center bg-primary-50 text-primary-700 text-xs sm:text-sm font-medium py-1.5 px-3 rounded-full mb-4">
                <i className="fas fa-tools text-[10px] sm:text-xs mr-1.5"></i>
                INSTALLATION
              </div>
              <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-3">New Appliance Setup</h3>
              <p className="text-gray-600 text-sm sm:text-base mb-6">Professional installation</p>
              <ul className="text-left text-sm sm:text-base mb-6 space-y-3">
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>All brands covered</span>
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>Flexible scheduling</span>
                </li>
                <li className="flex items-center text-sm">
                  <i className="fas fa-check text-green-500 mr-2 text-xs"></i>
                  <span>Installation warranty</span>
                </li>
              </ul>
              <div className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">From £90</div>
              <button 
                onClick={openBookingForm} 
                className="w-full bg-primary-500 text-white text-sm sm:text-base font-medium py-3 px-6 rounded-lg hover:bg-primary-600 transition-colors flex items-center justify-center"
              >
                <span>Book Installation</span>
                <i className="fas fa-arrow-right ml-2 text-xs"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="bg-white py-16 sm:py-20 lg:py-28">
        <div className="container mx-auto px-4 lg:px-8 xl:px-12">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 lg:mb-6">Why Choose Southeast Appliances?</h2>
          <p className="text-center text-gray-600 mb-12 lg:mb-16 max-w-3xl mx-auto text-base lg:text-lg">
            With thousands of successful repairs and a team of certified engineers, we're the trusted choice for appliance repairs across Southeast UK.
          </p>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
            <div className="bg-white rounded-xl shadow-lg p-6 sm:p-8 text-center transform hover:-translate-y-1 transition duration-300">
              <div className="bg-primary-50 w-16 h-16 sm:w-20 sm:h-20 rounded-full flex items-center justify-center mx-auto mb-4 sm:mb-6">
                <i className="fas fa-bolt text-3xl sm:text-4xl text-primary-500"></i>
              </div>
              <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-3 text-gray-800">Fast Response</h3>
              <div className="text-3xl sm:text-4xl font-bold text-primary-500 mb-2 sm:mb-3">90%</div>
              <p className="text-sm sm:text-base text-gray-600">Same-day service completion rate with clear fixed pricing from £80</p>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-6 sm:p-8 text-center transform hover:-translate-y-1 transition duration-300">
              <div className="bg-primary-50 w-16 h-16 sm:w-20 sm:h-20 rounded-full flex items-center justify-center mx-auto mb-4 sm:mb-6">
                <i className="fas fa-tools text-3xl sm:text-4xl text-primary-500"></i>
              </div>
              <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-3 text-gray-800">First-Time Fix</h3>
              <div className="text-3xl sm:text-4xl font-bold text-primary-500 mb-2 sm:mb-3">85%</div>
              <p className="text-sm sm:text-base text-gray-600">Repairs completed in first visit by expert engineers</p>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-6 sm:p-8 text-center transform hover:-translate-y-1 transition duration-300">
              <div className="bg-primary-50 w-16 h-16 sm:w-20 sm:h-20 rounded-full flex items-center justify-center mx-auto mb-4 sm:mb-6">
                <i className="fas fa-shield-alt text-3xl sm:text-4xl text-primary-500"></i>
              </div>
              <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-3 text-gray-800">Satisfaction Rate</h3>
              <div className="text-3xl sm:text-4xl font-bold text-primary-500 mb-2 sm:mb-3">98%</div>
              <p className="text-sm sm:text-base text-gray-600">Customer satisfaction with 90-day repair guarantee</p>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-6 sm:p-8 text-center transform hover:-translate-y-1 transition duration-300">
              <div className="bg-primary-50 w-16 h-16 sm:w-20 sm:h-20 rounded-full flex items-center justify-center mx-auto mb-4 sm:mb-6">
                <i className="fas fa-star text-3xl sm:text-4xl text-primary-500"></i>
              </div>
              <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-3 text-gray-800">Trusted Service</h3>
              <div className="text-3xl sm:text-4xl font-bold text-primary-500 mb-2 sm:mb-3">15+</div>
              <p className="text-sm sm:text-base text-gray-600">Years serving London & Southeast with excellence</p>
            </div>
          </div>
        </div>
      </section>

      {/* Types of Appliances We Service */}
      <section className="py-16 sm:py-20 lg:py-28 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-4 lg:px-8 xl:px-12">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 lg:mb-6">Types of Appliances We Service</h2>
          <p className="text-center text-gray-600 mb-12 lg:mb-16 max-w-3xl mx-auto text-base lg:text-lg">
            Expert repairs for all major household appliances, with same-day or next-day service guaranteed.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 max-w-6xl mx-auto">
            {applianceTypes.map((type, index) => {
              const imageMap = {
                'Washing Machine': washingMachineImg,
                'Dryer': dryerImg,
                'Dishwasher': dishwasherImg,
                'Oven': ovenImg,
                'Cooker': cookerImg,
                'Hob': hobImg,
                'Cooker Hood': cookerHoodImg,
                'Fridge': fridgeImg,
                'Freezer': freezerImg,
                'Fridge-Freezer': fridgeImg,
                'Microwave': microwaveImg
              };

              return (
                <Link 
                  key={index} 
                  to={applianceTypeToUrl[type] || '/services'}
                  className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden"
                >
                  <div className="p-3 sm:p-4 flex items-center space-x-3 sm:space-x-5">
                    <div className="relative w-20 sm:w-24 h-20 sm:h-24 flex-shrink-0 overflow-hidden rounded-lg bg-gray-50 border border-gray-100">
                      <img 
                        src={imageMap[type]} 
                        alt={`${type} repair service`}
                        className="w-full h-full object-contain p-3 group-hover:scale-110 transition-transform duration-300"
                      />
                      <div className="absolute inset-0 bg-gradient-to-br from-primary-500/0 to-primary-500/0 group-hover:from-primary-500/5 group-hover:to-primary-500/10 transition-all duration-300"></div>
                    </div>
                    <div className="flex-grow text-left">
                      <h3 className="font-semibold text-gray-800 mb-0.5 sm:mb-1 text-base sm:text-lg group-hover:text-primary-500 transition-colors duration-300">{type}</h3>
                      <p className="text-sm text-gray-500 mb-1 sm:mb-2">From £80</p>
                      <div className="text-primary-500 text-sm font-medium opacity-0 group-hover:opacity-100 transition-all duration-300 transform translate-x-2 group-hover:translate-x-0">
                        View Details →
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="text-center mt-12">
            <button onClick={openBookingForm} className="inline-flex items-center bg-primary-500 text-white font-bold py-3 px-8 rounded-full hover:bg-primary-600 transition duration-300">
              <i className="fas fa-calendar-alt mr-2"></i>
              Book a Repair
            </button>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <Testimonials openBookingForm={openBookingForm} />

      {/* Brands We Service */}
      <BrandsWeService openBookingForm={openBookingForm} />

      {/* Service Areas */}
      <ServiceAreasSection mapboxApiKey={mapboxApiKey} openBookingForm={openBookingForm} />

      {/* Call to Action */}
      <section className="bg-gradient-to-br from-primary-600 to-primary-500 text-white py-20">
        <div className="container mx-auto px-4 max-w-4xl">
          <div className="text-center mb-10">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Expert Appliance Repairs Today
            </h2>
            <p className="text-lg sm:text-xl text-white/90 font-medium">
              Professional service with 90-day guarantee • From £80
            </p>
          </div>
          
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6">
            <button 
              onClick={openBookingForm} 
              className="bg-white text-primary-600 px-8 py-4 rounded-lg font-semibold text-lg hover:bg-white/95 transition-colors duration-150 flex items-center justify-center min-w-[220px] shadow-lg"
            >
              <i className="fas fa-calendar-alt mr-3"></i>
              Book Online Now
            </button>
            <a 
              href="tel:+442078702206" 
              className="bg-primary-700/50 backdrop-blur-sm text-white px-8 py-4 rounded-lg font-semibold text-lg hover:bg-primary-700/60 transition-colors duration-150 flex items-center justify-center min-w-[220px]"
            >
              <i className="fas fa-phone-alt mr-3"></i>
              0330 818 3946
            </a>
          </div>
        </div>
      </section>

      {/* Floating Book Now Button */}
      <div className="fixed bottom-6 right-6 z-50">
        <button
          onClick={openBookingForm}
          className="bg-primary-500 text-white rounded-full p-4 shadow-lg hover:bg-primary-600 transition duration-300 transform hover:scale-105 flex items-center"
        >
          <i className="fas fa-wrench mr-2"></i>
          <span className="font-bold">Book Now</span>
        </button>
      </div>
    </div>
  );
};

export default Home;
