import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import locations from '../data/locations';
import applianceTypes from '../data/applianceTypes';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2VhLWNoYXJsaWUiLCJhIjoiY20xc2dlbzZyMDY3bzJqcjQwcnUxOXdidCJ9.1KGbWLCthsQFmo_SHbc-Yw';

const QuoteBar = ({ openBookingForm }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const quoteBarRef = useRef(null);
  const sentinelRef = useRef(null);
  const geocoderContainerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: MAPBOX_TOKEN,
      types: 'postcode',
      country: 'GB',
      placeholder: 'Enter your postcode',
    });

    geocoder.addTo(geocoderContainerRef.current);

    geocoder.on('result', (e) => {
      const [lng, lat] = e.result.center;
      const postcode = e.result.text;
      const closestLocation = findClosestLocation(lng, lat);
      setSelectedLocation(closestLocation);
      
      if (!isPostcodeInCoveredArea(postcode)) {
        setShowPopup(true);
      }
    });

    const handleScroll = () => {
      if (quoteBarRef.current && sentinelRef.current) {
        const quoteBarTop = quoteBarRef.current.getBoundingClientRect().top;
        const stickyTop = sentinelRef.current.getBoundingClientRect().top;
        setIsSticky(stickyTop <= 80 && quoteBarTop <= 80);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Custom styling for the geocoder input
    const geocoderInput = geocoderContainerRef.current.querySelector('.mapboxgl-ctrl-geocoder--input');
    if (geocoderInput) {
      geocoderInput.classList.add('custom-geocoder-input');
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      geocoder.onRemove();
    };
  }, []);

  // Reset sticky state when navigating to a new page
  useEffect(() => {
    setIsSticky(false);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const findClosestLocation = (lng, lat) => {
    let closestLocation = null;
    let minDistance = Infinity;

    locations.forEach(location => {
      location.regions.forEach(region => {
        region.towns.forEach(town => {
          const distance = calculateDistance(lng, lat, town.lng, town.lat);
          if (distance < minDistance) {
            minDistance = distance;
            closestLocation = { location, region, town };
          }
        });
      });
    });

    return closestLocation;
  };

  const calculateDistance = (lng1, lat1, lng2, lat2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lng2 - lng1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return R * c; // Distance in km
  };

  const isPostcodeInCoveredArea = (postcode) => {
    const coveredPostcodes = locations.flatMap(location => 
      location.regions.flatMap(region => region.postcodes)
    );
    return coveredPostcodes.some(prefix => postcode.toUpperCase().startsWith(prefix));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!selectedType) {
      setErrorMessage('Please select an appliance type.');
      return;
    }

    if (!selectedLocation) {
      setErrorMessage('Please enter your postcode.');
      return;
    }

    const { location, region, town } = selectedLocation;
    const locationSlug = location.name.toLowerCase().replace(/ /g, '-');
    const regionSlug = region.name.toLowerCase().replace(/ /g, '-');
    const townSlug = town.name.toLowerCase().replace(/ /g, '-');
    const serviceSlug = selectedType.toLowerCase().replace(/[^a-z0-9]+/g, '-');

    // Show confirmation popup instead of immediate navigation
    setShowConfirmation(true);
  };

  return (
    <div ref={quoteBarRef} className="quote-bar-wrapper">
      <div ref={sentinelRef} className="sticky-sentinel" />
      <div className={`bg-gradient-to-r from-primary-600 to-primary-500 py-4 sm:py-4 ${isSticky ? 'sticky-quote-bar' : ''}`} id="quote-bar">
        <div className="container mx-auto px-4 sm:px-4">
          <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-stretch sm:items-center justify-center gap-4 sm:gap-3 max-w-4xl mx-auto">
            <div className="flex-1 min-w-0 sm:max-w-[180px]">
              <select 
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="w-full h-12 sm:h-10 px-4 rounded-md text-base sm:text-sm bg-white/90 backdrop-blur-sm text-gray-700 border border-white/20 shadow-sm focus:outline-none focus:ring-2 focus:ring-white/30 appearance-none"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                  backgroundPosition: 'right 0.5rem center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '1.5em 1.5em',
                  paddingRight: '2.5rem',
                  WebkitAppearance: 'none'
                }}
              >
                <option value="" disabled>Select Appliance</option>
                {applianceTypes.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
            </div>

            <div className="flex-1 min-w-0">
              <div ref={geocoderContainerRef} className="custom-geocoder-container h-12 sm:h-10">
                {/* Mapbox Geocoder will be mounted here */}
              </div>
            </div>

            <button 
              type="submit" 
              className="h-12 sm:h-10 px-6 bg-white text-primary-600 font-medium rounded-md hover:bg-white/90 transition-colors duration-150 shadow-sm focus:outline-none focus:ring-2 focus:ring-white/30 whitespace-nowrap flex items-center justify-center text-base sm:text-sm w-full sm:w-auto"
            >
              <span>Get Quote</span>
              <i className="fas fa-arrow-right ml-2 text-xs"></i>
            </button>
          </form>

          {errorMessage && (
            <div className="text-white/90 text-sm mt-2 text-center backdrop-blur-sm bg-white/5 py-1 rounded">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md mx-4">
            <h3 className="text-xl font-bold mb-4">Area Not Typically Covered</h3>
            <p className="mb-4">We do not typically cover this area. However, on request, we may be able to provide service. Please call us for more information.</p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-primary-500 text-white px-4 py-2 rounded hover:bg-primary-600 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white p-8 rounded-xl shadow-xl max-w-2xl mx-4">
            <button
              onClick={() => setShowConfirmation(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
            >
              <i className="fas fa-times text-xl"></i>
            </button>
            <div className="text-center mb-6">
              <div className="bg-green-50 w-16 h-16 rounded-full mx-auto mb-4 flex items-center justify-center">
                <i className="fas fa-check-circle text-3xl text-green-500"></i>
              </div>
              <h3 className="text-2xl font-bold text-gray-800">Great News!</h3>
            </div>

            <div className="space-y-4 mb-8">
              <p className="text-lg text-center">
                We can repair your <span className="font-semibold text-primary-600">{selectedType}</span> in{' '}
                <span className="font-semibold text-primary-600">{selectedLocation.town.name}</span>
              </p>
              <div className="flex items-center justify-center gap-2 text-lg font-medium">
                <i className="fas fa-clock text-primary-500"></i>
                <span>As soon as Tomorrow</span>
              </div>
              <div className="flex items-center justify-center gap-2 text-lg font-medium">
                <i className="fas fa-tag text-primary-500"></i>
                <span>From £80</span>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <button
                onClick={() => {
                  setShowConfirmation(false);
                  openBookingForm({
                    serviceType: 'Repair',
                    applianceType: selectedType,
                    postcode: selectedLocation.town.postcode
                  });
                }}
                className="bg-primary-500 text-white px-6 py-4 rounded-lg hover:bg-primary-600 transition-all duration-200 text-center group"
              >
                <div className="flex items-center justify-center mb-1">
                  <i className="fas fa-calendar-check mr-2 group-hover:scale-110 transition-transform"></i>
                  <span>Book Now</span>
                </div>
                <span className="text-sm text-white/80">Instant Confirmation</span>
              </button>
              
              <button
                onClick={() => window.location.href = 'tel:0330 818 3946'}
                className="bg-green-500 text-white px-6 py-4 rounded-lg hover:bg-green-600 transition-all duration-200 text-center group w-full"
              >
                <div className="flex items-center justify-center mb-1">
                  <i className="fas fa-phone-alt mr-2 group-hover:scale-110 transition-transform"></i>
                  <span>Call Us Now</span>
                </div>
                <span className="text-sm text-white/80">24/7 Support</span>
              </button>
              
              <button
                onClick={() => {
                  setShowConfirmation(false);
                  const locationSlug = selectedLocation.location.name.toLowerCase().replace(/ /g, '-');
                  const regionSlug = selectedLocation.region.name.toLowerCase().replace(/ /g, '-');
                  const townSlug = selectedLocation.town.name.toLowerCase().replace(/ /g, '-');
                  const serviceSlug = selectedType.toLowerCase().replace(/[^a-z0-9]+/g, '-');
                  const url = `/service-areas/${locationSlug}${locationSlug !== regionSlug ? `/${regionSlug}` : ''}/${townSlug}/${serviceSlug}-repair`;
                  navigate(url);
                }}
                className="border-2 border-primary-500 text-primary-500 px-6 py-4 rounded-lg hover:bg-primary-50 transition-all duration-200 text-center group"
              >
                <div className="flex items-center justify-center mb-1">
                  <i className="fas fa-info-circle mr-2 group-hover:scale-110 transition-transform"></i>
                  <span>{selectedType} repairs in {selectedLocation.town.name}</span>
                </div>
                <span className="text-sm text-gray-500">View Service Details</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteBar;
