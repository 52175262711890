export const validateField = (name, value) => {
  switch (name) {
    case 'serviceType':
      return value ? '' : 'Please select a service type';
    case 'name':
      return value.trim() ? '' : 'Name is required';
    case 'email':
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address';
    case 'phone':
      return /^[0-9]{10,11}$/.test(value) ? '' : 'Invalid phone number (10-11 digits)';
    case 'address':
      return value.trim() ? '' : 'Address is required';
    case 'town':
      return value.trim() ? '' : 'Town is required';
    case 'postcode':
      return /^[A-Z]{1,2}[0-9][A-Z0-9]? [0-9][ABD-HJLNP-UW-Z]{2}$/.test(value.toUpperCase()) ? '' : 'Invalid UK postcode';
    case 'serviceDate':
      return value ? '' : 'Service date is required';
    case 'applianceType':
      return value ? '' : 'Please select an appliance type';
    case 'applianceBrand':
      return value ? '' : 'Please select a brand';
    case 'faultDescription':
      return value.trim() ? '' : 'Please describe the fault';
    case 'installationSpace':
      return value.trim() ? '' : 'Please describe the installation space';
    case 'installationNotes':
      return ''; // This field is optional
    default:
      return '';
  }
};

export const validateStep = (step, formData) => {
  const currentStepFields = {
    1: ['serviceType'],
    2: ['applianceType', 'applianceBrand'],
    3: ['name', 'email', 'phone', 'address', 'town', 'postcode', 'serviceDate'],
    4: [] // Review step - no validation needed
  };

  // Add conditional validation for repair/installation specific fields
  if (step === 2) {
    if (formData.serviceType === 'Repair') {
      currentStepFields[2].push('faultDescription');
    } else if (formData.serviceType === 'Installation') {
      currentStepFields[2].push('installationSpace');
    }
  }

  const stepErrors = {};
  if (currentStepFields[step]) {
    currentStepFields[step].forEach(field => {
      if (formData[field] !== undefined) {
        const error = validateField(field, formData[field]);
        if (error) {
          stepErrors[field] = error;
        }
      }
    });
  }

  return stepErrors;
};
