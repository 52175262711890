/** @type {import('./types').BrandDetails} */
const liebherr = {
  name: 'Liebherr',
  logo: 'liebherr.png',
  description: `Liebherr is renowned for their premium refrigeration appliances, combining German engineering with innovative cooling technology. Our extensive experience with Liebherr systems ensures we can diagnose and repair their sophisticated refrigeration units effectively, maintaining optimal performance and efficiency.`,
  specializations: [
    'Advanced cooling diagnostics',
    'Temperature control systems',
    'Electronic display repairs',
    'BioFresh technology expertise'
  ],
  applianceTypes: [
    'Refrigerators',
    'Freezers',
    'Wine Cabinets',
    'Combined Fridge-Freezers',
    'Built-in Units'
  ],
  commonIssues: [
    'Temperature regulation problems',
    'Ice maker malfunctions',
    'Door seal failures',
    'Control panel issues',
    'Cooling system faults',
    'BioFresh system problems'
  ],
  expertise: [
    'Refrigeration specialists',
    'Technical system knowledge',
    'Quality parts and service',
    'Temperature optimization',
    'Energy efficiency expertise'
  ],
  faqs: [
    {
      question: 'How long do Liebherr refrigerators typically last?',
      answer: 'Liebherr refrigerators are built to last 15-20 years with proper maintenance. Their robust German engineering and quality components contribute to this exceptional longevity.'
    },
    {
      question: 'Can you repair Liebherr\'s BioFresh system?',
      answer: 'Yes, we have extensive experience maintaining and repairing Liebherr\'s BioFresh system, ensuring optimal humidity and temperature control for food preservation.'
    },
    {
      question: 'Do you service built-in Liebherr appliances?',
      answer: 'Yes, we specialize in servicing both freestanding and built-in Liebherr refrigeration units, working carefully to maintain both functionality and aesthetic integration.'
    },
    {
      question: 'How often should Liebherr appliances be serviced?',
      answer: 'We recommend annual maintenance checks for Liebherr refrigeration appliances to ensure optimal performance and energy efficiency. Regular servicing helps prevent issues and extends appliance life.'
    }
  ]
};

module.exports = liebherr;
