import React from 'react';
import applianceDetails from '../data/applianceDetails';

// Import all brand logos
import aegLogo from '../assets/logos/brand-logos/aeg.png';
import bekoLogo from '../assets/logos/brand-logos/beko.png';
import bellingLogo from '../assets/logos/brand-logos/belling.png';
import blombergLogo from '../assets/logos/brand-logos/blomberg.png';
import boschLogo from '../assets/logos/brand-logos/bosch.png';
import britanniaLogo from '../assets/logos/brand-logos/britannia.png';
import capleLogo from '../assets/logos/brand-logos/caple.png';
import electroluxLogo from '../assets/logos/brand-logos/electrolux.png';
import elicaLogo from '../assets/logos/brand-logos/elica.png';
import falconLogo from '../assets/logos/brand-logos/falcon.png';
import fisherPaykelLogo from '../assets/logos/brand-logos/fisher-paykel.png';
import haierLogo from '../assets/logos/brand-logos/haier.png';
import hisenseLogo from '../assets/logos/brand-logos/hisense.png';
import hooverLogo from '../assets/logos/brand-logos/hoover.png';
import hotpointLogo from '../assets/logos/brand-logos/hotpoint.png';
import indesitLogo from '../assets/logos/brand-logos/indesit.png';
import lamonaLogo from '../assets/logos/brand-logos/lamona.png';
import leisureLogo from '../assets/logos/brand-logos/leisure.png';
import lgLogo from '../assets/logos/brand-logos/lg.png';
import liebherrLogo from '../assets/logos/brand-logos/liebherr.png';
import lofraLogo from '../assets/logos/brand-logos/lofra.png';
import mieleLogo from '../assets/logos/brand-logos/miele.png';
import neffLogo from '../assets/logos/brand-logos/neff.png';
import rangemasterLogo from '../assets/logos/brand-logos/rangemaster.png';
import samsungLogo from '../assets/logos/brand-logos/samsung.png';
import siemensLogo from '../assets/logos/brand-logos/siemens.png';
import smegLogo from '../assets/logos/brand-logos/smeg.png';
import stovesLogo from '../assets/logos/brand-logos/stoves.png';
import whirlpoolLogo from '../assets/logos/brand-logos/whirlpool.png';
import zanussiLogo from '../assets/logos/brand-logos/zanussi.png';

const logoMap = {
  'aeg.png': aegLogo,
  'beko.png': bekoLogo,
  'belling.png': bellingLogo,
  'blomberg.png': blombergLogo,
  'bosch.png': boschLogo,
  'britannia.png': britanniaLogo,
  'caple.png': capleLogo,
  'electrolux.png': electroluxLogo,
  'elica.png': elicaLogo,
  'falcon.png': falconLogo,
  'fisher-paykel.png': fisherPaykelLogo,
  'haier.png': haierLogo,
  'hisense.png': hisenseLogo,
  'hoover.png': hooverLogo,
  'hotpoint.png': hotpointLogo,
  'indesit.png': indesitLogo,
  'lamona.png': lamonaLogo,
  'leisure.png': leisureLogo,
  'lg.png': lgLogo,
  'liebherr.png': liebherrLogo,
  'lofra.png': lofraLogo,
  'miele.png': mieleLogo,
  'neff.png': neffLogo,
  'rangemaster.png': rangemasterLogo,
  'samsung.png': samsungLogo,
  'siemens.png': siemensLogo,
  'smeg.png': smegLogo,
  'stoves.png': stovesLogo,
  'whirlpool.png': whirlpoolLogo,
  'zanussi.png': zanussiLogo,
};

const BrandItem = ({ name, logo }) => (
  <div className="bg-white rounded-lg p-2 sm:p-3 shadow-sm hover:shadow-md flex items-center justify-center transition-all duration-300 hover:-translate-y-0.5 group border border-gray-100">
    <div className="w-20 sm:w-24 h-12 sm:h-14 flex items-center justify-center relative">
      {logoMap[logo] ? (
        <>
          <img 
            src={logoMap[logo]} 
            alt={name} 
            className="max-w-full max-h-full object-contain opacity-75 group-hover:opacity-100 transition-all duration-300" 
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent group-hover:to-primary-50/10 transition-all duration-300"></div>
        </>
      ) : (
        <span className="text-base font-semibold text-gray-800 text-center break-words">{name}</span>
      )}
    </div>
  </div>
);

const BrandsWeService = ({ openBookingForm }) => {
  const brandLogos = Object.entries(logoMap).map(([filename, logo]) => ({
    name: filename.replace('.png', '').split('-').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' '),
    logo: filename
  }));

  return (
    <section className="bg-gray-50" id="brands">
      <div className="container mx-auto px-4 lg:px-8 xl:px-12">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 lg:mb-6">Domestic Appliance Brands We Service</h2>
        <p className="text-center text-gray-600 mb-12 lg:mb-16 max-w-3xl mx-auto text-base lg:text-lg">
          Expert repairs for all major household appliance brands, with same-day or next-day service guaranteed.
        </p>
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-2 sm:gap-3 md:gap-4 max-w-7xl mx-auto">
          {brandLogos.map((brand, index) => (
            <BrandItem key={index} name={brand.name} logo={brand.logo} />
          ))}
        </div>
        <div className="mt-12 sm:mt-16 text-center">
          <p className="text-gray-600 text-base lg:text-lg">
            Don't see your appliance brand listed? We likely still service it!{' '}
            <button onClick={openBookingForm} className="text-primary-500 hover:text-primary-600 font-semibold">
              Contact us
            </button>{' '}
            for information about your specific appliance.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BrandsWeService;
