import React, { useState, useEffect } from 'react';
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import locations from '../data/locations';
import MapMarkers from './MapMarkers';

const ServiceAreasSection = ({ mapboxApiKey, openBookingForm }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [postcode, setPostcode] = useState('');
  
  const getMajorTowns = (location) => {
    const allTowns = location.regions.flatMap(r => r.towns);
    return allTowns.sort((a, b) => a.postcode.length - b.postcode.length).slice(0, 10);
  };

  const majorLocations = locations.map(location => ({
    ...location,
    regions: [{
      name: location.name,
      towns: getMajorTowns(location)
    }]
  }));

  const [viewport, setViewport] = useState({
    latitude: 51.4074,
    longitude: 0.0,
    zoom: 8.5
  });

  useEffect(() => {
    if (selectedRegion) {
      const location = majorLocations.find(loc => loc.name === selectedRegion.name);
      if (location && location.regions[0].towns.length > 0) {
        const firstTown = location.regions[0].towns[0];
        setViewport({
          latitude: firstTown.lat,
          longitude: firstTown.lng,
          zoom: 9.5
        });
      }
    }
  }, [selectedRegion, majorLocations]);

  const totalTowns = locations.reduce((acc, location) => 
    acc + location.regions.reduce((racc, region) => 
      racc + region.towns.length, 0), 0);

  const regions = locations.map(location => ({
    name: location.name,
    towns: location.regions.reduce((acc, region) => acc + region.towns.length, 0),
    postcodes: location.regions.reduce((acc, region) => acc.concat(region.postcodes), [])
  }));

  const scrollToPostcodeSearch = () => {
    const element = document.querySelector('.postcode-search');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handlePostcodeCheck = () => {
    const region = regions.find(r => r.postcodes.some(p => postcode.startsWith(p)));
    setSelectedRegion(region);
    if (region) {
      scrollToPostcodeSearch();
    }
  };

  return (
    <section className="bg-white py-16 sm:py-20 lg:py-24">
      <div className="container mx-auto px-4 lg:px-8 xl:px-12">
        <div className="text-center mb-12">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">Areas We Serve</h2>
          <p className="text-gray-600 text-base lg:text-lg max-w-3xl mx-auto">
            Professional appliance repairs across {regions.length} counties, covering over {totalTowns} towns in Southeast England
          </p>
        </div>

        {/* Coverage Statistics */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12">
          <div className="bg-gray-50 rounded-xl p-6 text-center">
            <div className="text-4xl font-bold text-primary-500 mb-2">{totalTowns}+</div>
            <div className="text-gray-600">Towns & Cities</div>
          </div>
          <div className="bg-gray-50 rounded-xl p-6 text-center">
            <div className="text-4xl font-bold text-primary-500 mb-2">90%</div>
            <div className="text-gray-600">Same-Day Service</div>
          </div>
          <div className="bg-gray-50 rounded-xl p-6 text-center">
            <div className="text-4xl font-bold text-primary-500 mb-2">30+</div>
            <div className="text-gray-600">Engineers</div>
          </div>
          <div className="bg-gray-50 rounded-xl p-6 text-center">
            <div className="text-4xl font-bold text-primary-500 mb-2">24/7</div>
            <div className="text-gray-600">Emergency Service</div>
          </div>
        </div>

        {/* Postcode Search */}
        <div className="max-w-2xl mx-auto mb-12 postcode-search scroll-mt-[120px] md:scroll-mt-0">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-semibold mb-4 text-center">Check if we cover your area</h3>
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="text"
                placeholder="Enter your postcode"
                value={postcode}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setPostcode(value);
                  if (value.length >= 2) {
                    const region = regions.find(r => r.postcodes.some(p => value.startsWith(p)));
                    setSelectedRegion(region);
                  }
                }}
                className="flex-grow px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
              />
              <button
                onClick={handlePostcodeCheck}
                className="bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition-colors flex items-center justify-center whitespace-nowrap"
              >
                <span>Check Coverage</span>
                <i className="fas fa-search ml-2"></i>
              </button>
            </div>
            {selectedRegion && (
              <div className="mt-4 p-6 bg-green-50 text-green-700 rounded-lg border-2 border-green-200 shadow-lg animate-fadeIn">
                <p className="font-medium">✓ Great news! We cover {selectedRegion.name}</p>
                <p className="text-sm mt-1">Book now for same-day or next-day service</p>
                <button
                  onClick={openBookingForm}
                  className="mt-3 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors text-sm"
                >
                  Book a Repair
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Main Map */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-12">
          <div id="map-container" className="h-[500px] w-full relative map-container scroll-mt-[120px] md:scroll-mt-0">
            {!isMapLoaded && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
                <div className="text-center">
                  <div className="w-12 h-12 border-4 border-primary-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
                  <p className="text-gray-600">Loading coverage map...</p>
                </div>
              </div>
            )}
            <Map
              mapboxAccessToken={mapboxApiKey}
              initialViewState={viewport}
              style={{width: '100%', height: '100%'}}
              mapStyle="mapbox://styles/mapbox/light-v11"
              onMove={evt => setViewport(evt.viewState)}
              onLoad={() => setIsMapLoaded(true)}
            >
              <MapMarkers 
                locations={majorLocations}
                selectedRegion={selectedRegion}
                onRegionSelect={setSelectedRegion}
              />
            </Map>
          </div>
        </div>

        {/* Region List */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {regions.map((region, index) => (
            <div 
              key={index}
              className={`bg-white rounded-lg shadow-md p-6 hover:shadow-lg hover:bg-gray-50 transition-all duration-300 cursor-pointer ${
                selectedRegion?.name === region.name 
                ? 'ring-4 ring-primary-500 shadow-xl transform -translate-y-1' 
                : 'hover:transform hover:-translate-y-1'
              }`}
              onClick={() => {
                setSelectedRegion(region);
                scrollToPostcodeSearch();
              }}
            >
              <h3 className="text-xl font-semibold mb-3">{region.name}</h3>
              <div className="text-gray-600 mb-4">
                <div className="mb-2">
                  <span className="font-medium">{region.towns}</span> towns covered
                </div>
                <div className="text-sm">
                  Postcodes: {region.postcodes.join(', ')}
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  openBookingForm();
                }}
                className="text-primary-500 hover:text-primary-600 font-medium text-sm inline-flex items-center"
              >
                Book a Repair
                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          ))}
        </div>

        {/* View All Locations */}
        <div className="text-center mt-8">
          <a 
            href="/service-areas" 
            className="inline-flex items-center text-primary-500 hover:text-primary-600 font-medium"
          >
            View Full Coverage Map
            <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </a>
        </div>

        {/* Call to Action */}
        <div className="text-center mt-12">
          <button
            onClick={openBookingForm}
            className="inline-flex items-center bg-primary-500 text-white font-bold py-3 px-8 rounded-full hover:bg-primary-600 transition duration-300"
          >
            <i className="fas fa-calendar-alt mr-2"></i>
            Book a Repair
          </button>
        </div>
      </div>
    </section>
  );
};

export default ServiceAreasSection;
