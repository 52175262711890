import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { pricing } from '../config';
import logo from '../assets/logos/SEA-Logo.png';
import { InputWithIcon, SelectWithIcon } from './FormComponents';
import RepairForm from './RepairForm';
import InstallationForm from './InstallationForm';
import { validateField, validateStep } from '../utils/formValidation';

const ProgressBar = ({ currentStep, totalSteps }) => (
  <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden mb-8">
    <motion.div
      className="absolute top-0 left-0 h-full bg-primary-500"
      initial={{ width: 0 }}
      animate={{ width: `${(currentStep / totalSteps) * 100}%` }}
      transition={{ duration: 0.3 }}
    />
  </div>
);

const StepIndicator = ({ currentStep, totalSteps, setStep }) => {
  const steps = [
    { icon: "fa-tools", label: "Service" },
    { icon: "fa-cog", label: "Appliance" },
    { icon: "fa-user", label: "Contact" },
    { icon: "fa-check", label: "Confirm" }
  ];
  
  return (
    <div className="flex justify-between items-center mb-8 relative">
      {steps.map((step, index) => (
        <div key={index} className="flex flex-col items-center relative z-10">
          <motion.div
            className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full flex items-center justify-center cursor-pointer
              ${index + 1 === currentStep 
                ? 'bg-primary-500 text-white' 
                : index + 1 < currentStep 
                  ? 'bg-primary-100 text-primary-500' 
                  : 'bg-gray-100 text-gray-400'}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => index + 1 <= currentStep && setStep(index + 1)}
          >
            <i className={`fas ${steps[index].icon} text-base sm:text-lg`}></i>
          </motion.div>
          <span className={`text-xs mt-2 font-medium ${
            index + 1 === currentStep 
              ? 'text-primary-500' 
              : index + 1 < currentStep 
                ? 'text-primary-400' 
                : 'text-gray-400'
          }`}>
            {steps[index].label}
          </span>
        </div>
      ))}
      <div className="absolute top-5 sm:top-6 left-0 w-full h-[2px] bg-gray-200 -z-10">
        <motion.div
          className="h-full bg-primary-500"
          initial={{ width: 0 }}
          animate={{ width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>
    </div>
  );
};

const ServiceTypeCard = ({ icon, label, description, selected, onClick }) => (
  <motion.div
    className={`flex flex-col items-center p-4 sm:p-6 rounded-xl cursor-pointer transition-all duration-300
      ${selected 
        ? 'bg-primary-50 border-2 border-primary-500 shadow-lg' 
        : 'bg-white border-2 border-gray-100 hover:border-primary-200 hover:shadow-md'}`}
    onClick={() => {
      console.log(`Selecting service type: ${label}`);
      onClick();
    }}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
  >
    <div className={`w-14 h-14 sm:w-16 sm:h-16 rounded-full flex items-center justify-center mb-3 sm:mb-4
      ${selected ? 'bg-primary-500 text-white' : 'bg-gray-100 text-gray-600'}`}>
      <i className={`fas ${icon} text-xl sm:text-2xl`}></i>
    </div>
    <h3 className={`text-base sm:text-lg font-semibold mb-2 ${selected ? 'text-primary-600' : 'text-gray-800'}`}>
      {label}
    </h3>
    <p className="text-sm text-gray-600 text-center">{description}</p>
    {selected && (
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="mt-3 sm:mt-4 text-primary-500"
      >
        <i className="fas fa-check-circle text-xl"></i>
      </motion.div>
    )}
  </motion.div>
);

const BookingForm = ({ isOpen, onClose, initialData = {} }) => {
  const formVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, y: 50, transition: { duration: 0.3 } }
  };

  const [step, setStep] = useState(1);
  const totalSteps = 4;
  const [formData, setFormData] = useState({
    serviceType: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    town: '',
    postcode: '',
    serviceDate: '',
    applianceType: '',
    applianceBrand: '',
    faultDescription: '',
    installationNotes: '',
  });

  useEffect(() => {
    if (initialData) {
      setFormData(prevData => ({
        ...prevData,
        ...initialData
      }));
      if (initialData.serviceType) {
        setStep(2); // Skip to appliance details if service type is provided
      }
    }
  }, [initialData]);

  const [errors, setErrors] = useState({});
  const [quote, setQuote] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    console.log('Form data updated:', formData);
  }, [formData]);

  useEffect(() => {
    console.log('Step changed to:', step);
  }, [step]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed: ${name} = ${value}`);
    setFormData(prevData => ({ ...prevData, [name]: value }));
    const error = validateField(name, value);
    console.log(`Validation for ${name}:`, error);
    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };

  const calculateQuote = () => {
    const serviceType = formData.serviceType?.toLowerCase() || '';
    const serviceTypeFee = pricing.serviceTypeFees[serviceType] || 0;
    const applianceType = formData.applianceType?.replace(/_/g, ' ') || 'OTHER';
    const basePrice = pricing.appliancePricing[applianceType] || pricing.appliancePricing['OTHER'];
    const brand = formData.applianceBrand?.replace(/_/g, ' ') || 'OTHER';
    const brandModifier = pricing.brandModifiers[brand] || pricing.brandModifiers['OTHER'];
    
    return {
      basePrice,
      brandModifier,
      serviceTypeFee,
      total: basePrice + brandModifier + serviceTypeFee
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted. Current step:', step);
    console.log('Current form data:', formData);
    
    const stepErrors = validateStep(step, formData);
    console.log('Step validation errors:', stepErrors);
    setErrors(stepErrors);

    if (Object.keys(stepErrors).length > 0) {
      console.log('Validation failed for step:', step);
      return;
    }

    if (step < totalSteps) {
      console.log('Moving to next step:', step + 1);
      setStep(step + 1);
    } else {
      setIsLoading(true);
      try {
        const calculatedQuote = calculateQuote();
        console.log('Submitting booking with quote:', calculatedQuote);
        
        // Clean and validate the form data
        const cleanFormData = {
          serviceType: String(formData.serviceType || ''),
          name: String(formData.name || ''),
          email: String(formData.email || ''),
          phone: String(formData.phone || ''),
          address: String(formData.address || ''),
          town: String(formData.town || ''),
          postcode: String(formData.postcode || ''),
          serviceDate: String(formData.serviceDate || ''),
          applianceType: String(formData.applianceType || ''),
          applianceBrand: String(formData.applianceBrand || ''),
          faultDescription: String(formData.faultDescription || ''),
          installationNotes: String(formData.installationNotes || ''),
        };

        // Clean the quote details
        const cleanQuote = {
          basePrice: Number(calculatedQuote.basePrice || 0),
          brandModifier: Number(calculatedQuote.brandModifier || 0),
          serviceTypeFee: Number(calculatedQuote.serviceTypeFee || 0),
          total: Number(calculatedQuote.total || 0),
        };

        const response = await fetch('/api/submit-booking', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...cleanFormData, quoteDetails: cleanQuote }),
        });

        if (!response.ok) {
          throw new Error('Failed to submit booking');
        }

        const result = await response.json();
        if (result.success) {
          setSuccessMessage('Your booking has been confirmed! We will contact you shortly with more details.');
          
          // Google Ads Conversion Tracking
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              'send_to': 'AW-10894369835/RDATCMjThNwZEKvI68oo',
              'value': calculatedQuote.total,
              'currency': 'GBP'
            });
          }
          
          // Reset form
          setFormData({
            serviceType: '',
            name: '',
            email: '',
            phone: '',
            address: '',
            town: '',
            postcode: '',
            serviceDate: '',
            applianceType: '',
            applianceBrand: '',
            faultDescription: '',
            installationNotes: '',
          });
          setQuote(null);
          setStep(1);
        }
      } catch (error) {
        console.error('Booking submission error:', error);
        alert('There was an error submitting your booking. Please try again.');
      }
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-6">Select Service Type</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
              <ServiceTypeCard
                icon="fa-wrench"
                label="Repair"
                description="Fix your faulty appliance with our expert repair service"
                selected={formData.serviceType === 'Repair'}
                onClick={() => {
                  console.log('Setting service type to Repair');
                  setFormData(prev => {
                    const newData = { ...prev, serviceType: 'Repair' };
                    console.log('Updated form data:', newData);
                    return newData;
                  });
                }}
              />
              <ServiceTypeCard
                icon="fa-box"
                label="Installation"
                description="Professional installation of your new appliance"
                selected={formData.serviceType === 'Installation'}
                onClick={() => {
                  console.log('Setting service type to Installation');
                  setFormData(prev => {
                    const newData = { ...prev, serviceType: 'Installation' };
                    console.log('Updated form data:', newData);
                    return newData;
                  });
                }}
              />
            </div>
            {errors.serviceType && (
              <p className="text-red-500 text-sm mt-2">{errors.serviceType}</p>
            )}
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
          >
            <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-6">Appliance Details</h3>
            {formData.serviceType === 'Repair' && (
              <RepairForm formData={formData} handleInputChange={handleInputChange} errors={errors} />
            )}
            {formData.serviceType === 'Installation' && (
              <InstallationForm formData={formData} handleInputChange={handleInputChange} errors={errors} />
            )}
          </motion.div>
        );

      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
          >
            <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-6">Contact Details</h3>
            <div className="space-y-4">
              <InputWithIcon
                icon="fa-user"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Your Name"
                required
                label="Name"
                error={errors.name}
              />
              <InputWithIcon
                icon="fa-envelope"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email Address"
                required
                label="Email"
                error={errors.email}
              />
              <InputWithIcon
                icon="fa-phone"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                required
                label="Phone"
                error={errors.phone}
              />
              <InputWithIcon
                icon="fa-home"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Street Address"
                required
                label="Address"
                error={errors.address}
              />
              <div className="grid grid-cols-2 gap-4">
                <InputWithIcon
                  icon="fa-city"
                  type="text"
                  name="town"
                  value={formData.town}
                  onChange={handleInputChange}
                  placeholder="Town/City"
                  required
                  label="Town"
                  error={errors.town}
                />
                <InputWithIcon
                  icon="fa-map-marker-alt"
                  type="text"
                  name="postcode"
                  value={formData.postcode}
                  onChange={handleInputChange}
                  placeholder="Postcode"
                  required
                  label="Postcode"
                  error={errors.postcode}
                />
              </div>
              <InputWithIcon
                icon="fa-calendar"
                type="date"
                name="serviceDate"
                value={formData.serviceDate}
                onChange={handleInputChange}
                required
                label="Preferred Service Date"
                error={errors.serviceDate}
              />
            </div>
          </motion.div>
        );

      case 4:
        const calculatedQuote = calculateQuote();
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="space-y-6"
          >
            <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-6">Review & Confirm</h3>
            
            <div className="bg-gray-50 rounded-xl p-4 sm:p-6 space-y-4">
              <div className="flex justify-between items-center pb-4 border-b border-gray-200">
                <h4 className="text-base sm:text-lg font-semibold text-gray-800">Service Details</h4>
                <button 
                  onClick={() => setStep(1)}
                  className="text-primary-500 hover:text-primary-600 text-sm font-medium"
                >
                  Edit
                </button>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Service Type</p>
                  <p className="font-medium">{formData.serviceType}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Date</p>
                  <p className="font-medium">{formData.serviceDate}</p>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 rounded-xl p-4 sm:p-6 space-y-4">
              <div className="flex justify-between items-center pb-4 border-b border-gray-200">
                <h4 className="text-base sm:text-lg font-semibold text-gray-800">Appliance Details</h4>
                <button 
                  onClick={() => setStep(2)}
                  className="text-primary-500 hover:text-primary-600 text-sm font-medium"
                >
                  Edit
                </button>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Type</p>
                  <p className="font-medium">{formData.applianceType}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Brand</p>
                  <p className="font-medium">{formData.applianceBrand}</p>
                </div>
              </div>
              {formData.serviceType === 'Repair' && (
                <div className="mt-4">
                  <p className="text-sm text-gray-500">Fault Description</p>
                  <p className="font-medium">{formData.faultDescription}</p>
                </div>
              )}
              {formData.serviceType === 'Installation' && (
                <div className="mt-4">
                  <p className="text-sm text-gray-500">Installation Notes</p>
                  <p className="font-medium">{formData.installationNotes || 'None provided'}</p>
                </div>
              )}
            </div>

            <div className="bg-gray-50 rounded-xl p-4 sm:p-6 space-y-4">
              <div className="flex justify-between items-center pb-4 border-b border-gray-200">
                <h4 className="text-base sm:text-lg font-semibold text-gray-800">Contact Details</h4>
                <button 
                  onClick={() => setStep(3)}
                  className="text-primary-500 hover:text-primary-600 text-sm font-medium"
                >
                  Edit
                </button>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Name</p>
                  <p className="font-medium">{formData.name}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Phone</p>
                  <p className="font-medium">{formData.phone}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Email</p>
                  <p className="font-medium">{formData.email}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Address</p>
                  <p className="font-medium">{formData.address}</p>
                  <p className="font-medium">{formData.town}, {formData.postcode}</p>
                </div>
              </div>
            </div>

            <div className="bg-primary-50 rounded-xl p-4 sm:p-6">
              <h4 className="text-base sm:text-lg font-semibold text-gray-800 mb-4">Quote Summary</h4>
              <div className="space-y-2">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Base Price</span>
                  <span className="font-medium">£{calculatedQuote.basePrice}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Brand Adjustment</span>
                  <span className="font-medium">£{calculatedQuote.brandModifier}</span>
                </div>
                {calculatedQuote.serviceTypeFee > 0 && (
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Service Fee</span>
                    <span className="font-medium">£{calculatedQuote.serviceTypeFee}</span>
                  </div>
                )}
                <div className="border-t border-gray-200 mt-4 pt-4">
                  <div className="flex justify-between text-lg font-bold">
                    <span className="text-gray-800">Total</span>
                    <span className="text-primary-600">£{calculatedQuote.total}</span>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        );

      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto"
          onClick={onClose}
        >
          <motion.div
            variants={formVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="min-h-screen px-4 text-center"
            onClick={e => e.stopPropagation()}
          >
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <motion.div
              className="inline-block w-full max-w-4xl p-4 sm:p-6 my-8 text-left align-middle bg-white shadow-xl rounded-2xl relative"
            >
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
              >
                <i className="fas fa-times text-xl"></i>
              </button>

              <div className="flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left gap-4 sm:gap-6 mb-8">
                <img src={logo} alt="Southeast Appliances" className="h-12 sm:h-14" />
                <div className="flex-1">
                  <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-1">Book a Service</h2>
                  <p className="text-gray-600 text-base sm:text-lg">Expert appliance repairs across Southeast England</p>
                </div>
              </div>

              {successMessage ? (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-green-50 border-l-4 border-green-500 p-6 rounded-lg"
                >
                  <div className="flex items-center mb-4">
                    <div className="flex-shrink-0">
                      <i className="fas fa-check-circle text-green-500 text-2xl"></i>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-medium text-green-800">Booking Confirmed!</h3>
                      <p className="text-green-700">{successMessage}</p>
                    </div>
                  </div>
                  <button
                    onClick={onClose}
                    className="mt-4 bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition-colors"
                  >
                    Close
                  </button>
                </motion.div>
              ) : (
                <form 
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log('Form submitted');
                    handleSubmit(e);
                  }} 
                  className="space-y-8"
                >
                  <StepIndicator currentStep={step} totalSteps={totalSteps} setStep={setStep} />
                  <ProgressBar currentStep={step} totalSteps={totalSteps} />

                  {renderStepContent()}

                  <div className="flex justify-between mt-8 pt-4 border-t border-gray-200">
                    {step > 1 && (
                      <button
                        type="button"
                        onClick={() => setStep(step - 1)}
                        className="px-6 py-2 text-gray-600 hover:text-gray-800 font-medium transition-colors"
                      >
                        <i className="fas fa-arrow-left mr-2"></i>
                        Back
                      </button>
                    )}
                    <button
                      type="submit"
                      disabled={isLoading}
                      className={`px-8 py-3 bg-primary-500 text-white rounded-lg font-medium 
                        hover:bg-primary-600 transition-colors ml-auto flex items-center
                        ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
                    >
                      {isLoading ? (
                        <>
                          <motion.div
                            animate={{ rotate: 360 }}
                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                            className="w-5 h-5 border-2 border-white border-t-transparent rounded-full mr-2"
                          />
                          Processing...
                        </>
                      ) : step === totalSteps ? (
                        'Confirm Booking'
                      ) : (
                        <>
                          Continue
                          <i className="fas fa-arrow-right ml-2"></i>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BookingForm;
