/** @type {import('./types').BrandDetails} */
const fisherPaykel = {
  name: 'Fisher & Paykel',
  logo: 'fisher-paykel.png',
  description: `Fisher & Paykel is known for innovative premium appliances that combine sophisticated technology with elegant design. Our deep understanding of their unique features and advanced systems ensures we can provide expert repairs that maintain the exceptional performance of your Fisher & Paykel appliances.`,
  specializations: [
    'Advanced diagnostics',
    'Drawer system expertise',
    'Smart feature repairs',
    'Performance optimization'
  ],
  applianceTypes: [
    'Dishwashers',
    'Refrigerators',
    'Washing Machines',
    'Dryers',
    'Ovens'
  ],
  commonIssues: [
    'Drawer mechanism problems',
    'Control panel malfunctions',
    'Water inlet issues',
    'Temperature control faults',
    'Smart system errors',
    'Door seal failures'
  ],
  expertise: [
    'Innovative system specialists',
    'Technical problem-solving',
    'Quality-focused service',
    'Drawer technology experts',
    'Comprehensive repairs'
  ],
  faqs: [
    {
      question: 'Can you repair Fisher & Paykel DishDrawer™ systems?',
      answer: 'Yes, we have extensive experience with Fisher & Paykel\'s unique DishDrawer™ technology, ensuring proper function of both single and double drawer systems.'
    },
    {
      question: 'Do you service Fisher & Paykel\'s smart features?',
      answer: 'Yes, we have expertise in diagnosing and repairing Fisher & Paykel\'s smart appliance features, maintaining connectivity and advanced functionality.'
    },
    {
      question: 'How long do Fisher & Paykel appliances last?',
      answer: 'With proper maintenance, Fisher & Paykel appliances typically last 10-15 years. Regular servicing by experienced technicians helps maximize their lifespan and performance.'
    },
    {
      question: 'Do you repair integrated Fisher & Paykel appliances?',
      answer: 'Yes, we specialize in servicing both freestanding and integrated Fisher & Paykel appliances, ensuring proper function while maintaining kitchen aesthetics.'
    }
  ]
};

module.exports = fisherPaykel;
