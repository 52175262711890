/** @type {import('./types').BrandDetails} */
const miele = {
  name: 'Miele',
  logo: 'miele.png',
  description: `Miele is renowned for their premium domestic appliances. With over two decades of experience repairing Miele appliances, we understand their sophisticated technology and exceptional build quality. Our expertise ensures your Miele appliance receives the high-quality service it deserves.`,
  specializations: [
    'Advanced diagnostic expertise',
    'Complex electronic system repairs',
    'Premium appliance specialists',
    'Long-term maintenance solutions'
  ],
  applianceTypes: [
    'Washing Machines',
    'Dryers',
    'Dishwashers',
    'Refrigerators',
    'Freezers'
  ],
  commonIssues: [
    'Electronic control board malfunctions',
    'Door lock mechanism failures',
    'Water inlet valve issues',
    'Heating element problems',
    'Drum bearing wear',
    'Pump and drainage problems'
  ],
  expertise: [
    'Extensive Miele repair experience',
    'In-depth technical knowledge',
    'Quality parts and solutions',
    'Proven track record',
    'Rapid response service'
  ],
  faqs: [
    {
      question: 'How long do Miele appliances typically last?',
      answer: 'Miele appliances are designed and tested to last for 20 years or more with proper maintenance. This exceptional longevity is one of the key reasons for their premium pricing and reputation for quality.'
    },
    {
      question: 'Are spare parts readily available for Miele appliances?',
      answer: 'Yes, we maintain a comprehensive stock of genuine Miele spare parts. Miele is known for their excellent parts availability, often supporting models that are 15+ years old.'
    },
    {
      question: 'Do you use genuine Miele parts for repairs?',
      answer: 'Yes, we use genuine Miele parts to ensure optimal performance and longevity of your appliance. Our experience has shown that genuine parts provide the best reliability and maintain your appliance\'s quality.'
    },
    {
      question: 'How often should Miele appliances be serviced?',
      answer: 'We recommend annual maintenance checks for Miele appliances to ensure optimal performance and prevent potential issues. Regular maintenance by experienced technicians helps extend the life of these premium appliances.'
    }
  ]
};

module.exports = miele;
