/** @type {import('./types').BrandDetails} */
const neff = {
  name: 'Neff',
  logo: 'neff.png',
  description: `Neff is a premium appliance manufacturer known for innovative solutions and reliable performance. Our years of experience with Neff appliances ensure we understand their unique features and can provide expert repairs that maintain their exceptional performance.`,
  specializations: [
    'Advanced system diagnostics',
    'Performance optimization',
    'Technical troubleshooting',
    'Long-term solutions'
  ],
  applianceTypes: [
    'Dishwashers',
    'Refrigerators',
    'Freezers',
    'Washing Machines',
    'Dryers'
  ],
  commonIssues: [
    'Temperature control problems',
    'Electronic system issues',
    'Control panel malfunctions',
    'Sensor calibration',
    'Water system problems',
    'Heating element failures'
  ],
  expertise: [
    'Extensive Neff experience',
    'Technical specialists',
    'Quality-focused service',
    'Proven reliability',
    'Efficient solutions'
  ],
  faqs: [
    {
      question: 'What is special about Neff\'s Slide&Hide® oven door?',
      answer: 'The Slide&Hide® is Neff\'s unique fully retracting oven door that provides better access to the oven cavity. Our experience with this innovative feature ensures we can maintain and repair it effectively.'
    },
    {
      question: 'Can you service Neff\'s CircoTherm® system?',
      answer: 'Yes, we have extensive experience maintaining and repairing Neff\'s CircoTherm® system, ensuring optimal heat distribution and cooking performance.'
    },
    {
      question: 'Do you repair Neff\'s integrated appliances?',
      answer: 'Yes, we specialize in servicing fully integrated Neff appliances, working carefully to maintain both functionality and aesthetic integration with your kitchen design.'
    },
    {
      question: 'How often should Neff appliances be serviced?',
      answer: 'We recommend annual maintenance for Neff appliances to ensure optimal performance. Regular servicing by experienced technicians helps prevent issues and extends appliance life.'
    }
  ]
};

module.exports = neff;
